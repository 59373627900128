import {
	compose as c, concat, groupBy, has,	nthArg,
	prop, map, mapObjIndexed, mergeWith, uniq, 
} from 'ramda';

export default {
	byId: {
		'app.getUserDomainSuccess': (state, action) => {
            const { payload } = action;
            const { userDomainResult: { publications }} = payload;
			publications.forEach(publication => {
				state[ publication._id ] = publication;
			});
		},
		'search.popularSuccess': (state, action) => {
            const { payload } = action;
            const { result } = payload;
			result.publications.forEach(publication => {
				state[ publication._id ] = publication;
			});
		},
		'search.searchSuccess': (state, action) => {
            const { payload } = action;
            const { result } = payload;
			result.publications.forEach(publication => {
				state[ publication._id ] = publication;
			});
		},
	},
	allIds: {
		'app.getUserDomainSuccess': (state, action) => {

            const { payload } = action;
            const { userDomainResult: { publications }} = payload;
			const publicationIds = c(
				map(prop('_id')),
			)(publications);

			const ids = new Set([ ...state, ...publicationIds ]);

			state.splice(0, state.length, ...ids);
		},
		'search.popularSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const publicationIds = c(
				map(prop('_id')),
				prop('publications')
			)(result);

			const ids = new Set([ ...state, ...publicationIds ]);

			state.splice(0, state.length, ...ids);
		},
		'search.searchSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const publicationIds = c(
				map(prop('_id')),
				prop('publications')
			)(result);

			const ids = new Set([ ...state, ...publicationIds ]);

			state.splice(0, state.length, ...ids);
		},
	},
};
