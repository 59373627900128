import { Component, useEffect } from 'react';
import cn from 'classnames/bind';
import { connect } from 'react-redux';
import { compose as c } from 'ramda';

import $app from '@state/app';
import $users from '@state/users';

import Link from '@ui/components/link';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
		stack: $app.selectors.getMobileMenuStack(state, props),
		mobile: $app.selectors.getMobile(state, props),
		last: $app.selectors.getMobileMenuLastPushed(state, props),
		user: $users.selectors.getLoggedInUser(state, props),
	}),
	{
		pushMenu: $app.actions.pushMenu,
		popMenu: $app.actions.popMenu,
		setVisible: $app.actions.setVisible,
	}
)(
	c(
		({
			user,
			stack,
			mobile,
			last,
			pushMenu,
			popMenu,
			setVisible,
			menuData,
			lastMenuItem,
		}) => (
			<div className={ sx('root', { invisible: mobile.visible !== 'menu' }) }>
				<header className={ s.header }>
					<div
						className={ sx('back', { invisible: stack.length === 0 }) }
						onClick={ popMenu }
					>
						<span className={ m[ 'icon-arrow-left2' ] } />
					</div>
					<div className={ s.title }>
						{
							!!lastMenuItem && lastMenuItem.title
						}
					</div>
					<div
						className={ s.close }
						onClick={ () => setVisible({ comp: mobile.prevVisible }) }
					>
						<img src='/imgs/icon-close.png' />
					</div>
				</header>
				<div className={ s.main }>
					{
						last ?
							lastMenuItem.component :
							menuData.map(({ id, title, label, icon, link, mode }) => 
								link ? (
									<div
										key={ id }
										className={ s.menuRow }
										onClick={ () => setVisible({ comp: mobile.prevVisible }) }
									>
										<Link
											className={ s.link }
											to={{
												pathname: typeof link === 'function' ?
													link({ user }) :
													link,
											}}
										>
											<span className={ cn(mx(icon), sx('icon')) } />
											<span className={ s.menuTitle }>{ title }</span>
										</Link>
									</div>
								) : (
									<div
										key={ id }
										className={ s.menuRow }
										onClick={
											() => mode ?
												setVisible({ comp: mode }) :
												pushMenu({ id })
										}
									>
										<span className={ cn(mx(icon), sx('icon')) } />
										<span className={ s.menuTitle }>{ title }</span>
									</div>
								)
							)
					}
				</div>
			</div>
		)
	)
);
