import { createReducer } from '@lib/utils/redux';
import config from '@nl/tags/config.json';
import actions from './actions';
import reducers from './reducers';
import saga from './sagas';
import selectors from './selectors';

const reducer = createReducer(config, actions, reducers);

export { actions };
export { saga };
export { selectors };
export { reducer };

export default {
    actions,
    selectors,
    reducer,
    saga,
};
