import { createReducer } from '@lib/utils/redux';
import config from './config.json';
import actions from './actions';

const reducerImports = {};

const reducers = {
    open: (state, action) => {
        state.isOpen = true;
        state.type = action.payload.type;
        state.props = action.payload.props;
    },
    close: (state, action) => {
        state.isOpen = false;
        state.type = null;
        state.props = action.payload.props;
    },
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
