import { assoc, compose, filter, prop, propEq, reduce, toPairs } from 'ramda';
import axiosClient, { defaultsFromConf } from '@lib/axios-client';

export default compose(
    reduce(
        (acc, [ name, conf ]) => assoc(
            name,
            axiosClient(defaultsFromConf(conf)),
            acc
        ),
        {}
    ),
    filter(([ name, conf ]) => propEq('api', 'rest', conf)),
    toPairs,
    prop('async'),
    prop('actions'),
);
