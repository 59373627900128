import { createReducer } from '@lib/utils/redux';
import config from '@nl/users/config.json';
import actions from './actions';

import { actions as app } from '@state/app';
import { actions as auth } from '@state/auth';
import { actions as groups } from '@state/groups';

const reducerImports = {
    app,
    auth,
    groups,
};

const reducers = {
	byId: {
        'auth.loginSuccess': (state, action) => {
            const { payload } = action;
            const { user } = payload;
			state[ user._id ] = user;
        },
		'app.getUserDomainSuccess': (state, action) => {
            const { payload } = action;
            const { userDomainResult: { user }} = payload;
			state[ user._id ] = user;
		},
		'groups.updateGroupSuccess': (state, action) => {

            const { payload, meta } = action;
            const { groupUpdateResult: { group } } = payload;
			const { userId, groupId } = meta.req;

			state[ userId ].groups = state[ userId ].groups.map(g => {
				if (g._id === groupId) {
					return group;
				}
				return g;
			});
		},
		'groups.createGroupSuccess': (state, action) => {

            const { payload, meta } = action;
            const { group } = payload;
			const { input: { userId }} = meta.req;

			state[ userId ].groups.push(group);
		},
		'groups.deleteGroupSuccess': (state, action) => {

            const { payload, meta } = action;
            const { group } = payload;
			const { userId, groupId } = meta.req;

			state[ userId ].groups = state[ userId ].groups.filter(
				g => g._id !== groupId
			);
		},
		'groups.addItemSuccess': (state, action) => {
            const { payload, meta } = action;
            const { group } = payload;
			state[ userId ].groups = groups;
		},
        addBookmarkSuccess: (state, action) => {
            const { payload } = action;
            const { user } = payload;
			state[ user._id ] = user;
			state[ user._id ].bookmarks = user.bookmarks;
		},
        removeBookmarkSuccess: (state, action) => {
            const { payload } = action;
            const { user } = payload;
			state[ user._id ] = user;
			state[ user._id ].bookmarks = user.bookmarks;
		},
        setCurrentViewSuccess: (state, action) => {
            const { payload } = action;
            const { setViewResult } = payload;
			const { user } = setViewResult;
			state[ user._id ] = user;
		},
        subscribeSuccess: (state, action) => {
            const { payload } = action;
            const { subscribeResult } = payload;
			const { user } = subscribeResult;
			state[ user._id ] = user;
			state[ user._id ].groups = user.groups; //trigger state change
		},
        unsubscribeSuccess: (state, action) => {
            const { payload } = action;
            const { unsubscribeResult } = payload;
			const { user } = unsubscribeResult;
			state[ user._id ] = user;
			state[ user._id ].groups = user.groups; //trigger state change
		},
        updateSettingsSuccess: (state, action) => {
            const user = action?.payload?.user;
            state[ user._id ] = user;
            state[ user._id ].settings = user.settings;
        },
	},
	idsByUsername: {
		'auth.loginSuccess': (state, action) => {
            const { payload } = action;
            const { user } = payload;
			state[ user.username ] = user._id;
		},
        getUserSuccess: (state, action) => {
            const { payload } = action;
            const { user } = payload;
			state[ user.username ] = user._id;
		},
        setCurrentViewSuccess: (state, action) => {
            const { payload } = action;
            const { setViewResult } = payload;
			const { user } = setViewResult;
			state[ user.username ] = user._id;
		},
        subscribeSuccess: (state, action) => {
            const { payload } = action;
            const { subscribeResult } = payload;
			const { user } = subscribeResult;
			state[ user.username ] = user._id;
		},
        unsubscribeSuccess: (state, action) => {
            const { payload } = action;
            const { unsubscribeResult } = payload;
			const { user } = unsubscribeResult;
			state[ user.username ] = user._id;
		},
	},
	allIds: {
        'auth.loginSuccess': (state, action) => {
            const { payload } = action;
            const { user } = payload;
            if (!state.includes(user._id)) {
				state.push(user._id);
			}
        },
		'app.getUserDomainSuccess': (state, action) => {
            const { payload } = action;
            const { userDomainResult: { user }} = payload;
			if (!state.includes(user._id)) {
				state.push(user._id);
			}
		},
        setCurrentViewSuccess: (state, action) => {
            const { payload } = action;
            const { setViewResult } = payload;
			const { user } = setViewResult;
			state.push(user._id);
		},
        subscribeSuccess: (state, action) => {
            const { payload } = action;
            const { subscribeResult } = payload;
			const { user } = subscribeResult;
			state.push(user._id);
		},
        unsubscribeSuccess: (state, action) => {
            const { payload } = action;
            const { unsubscribeResult } = payload;
			const { user } = unsubscribeResult;
			state.push(user._id);
		},
	},
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
