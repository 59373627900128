import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import HeaderBtn from '@ui/components/header-btn';
import Icon from '@ui/components/icon';
import cn from 'classnames/bind';
import m from '@ui/styles/icomoon.css';
import s from './styles.scss';

const sx = cn.bind(s);

export default ({ layouts, toggle }) => (
    <div className={ s.root }>
        { layouts.reduce(
            (btns, layout) => [
                ...btns,
                (
                    <div
                        key={ layout.id }
                        className={ sx('btn', { active: layout.active }) }
                        title={ layout.title }
                        onClick={ () => toggle(layout.id) }
                    >
                        <Icon { ...layout.icon } />
                    </div>
                ),
            ],
            []
        )}
    </div>
);
