import { createSelector } from '@reduxjs/toolkit';
import {
    compose as c, identity, map, omit,
    prop, propEq, reduce, reject, toPairs
} from 'ramda';

import $users from '@state/users';
import $feeds from '@state/feeds';
import $publications from '@state/publications';

import { getOpts } from '@state/users/utils';
import { feedOptData, pubOptData, getCurrentPool } from './utils';

const self = state => state.groupPanel;

export const getSelectedItems = createSelector(self, prop('selectedItems'));

export const makeGetSelectedItems = () => createSelector(getSelectedItems, identity);

export const getCurrentValue = createSelector(
    [
        $publications.selectors.getPublicationsMap,
        $feeds.selectors.getFeedsMap,
        getSelectedItems,
    ],
    (publications, feeds, groupItems) => map(
        ({ id, type }) => {
            switch (type) {
                case 'publication':
                    return pubOptData(publications[ id ]);

                case 'feed':
                    return feedOptData(feeds[ id ]);
            }
        },
        groupItems
    ),
);

export const makeGetCurrentValue = () => createSelector(getCurrentValue, identity);

export const getOptions = createSelector(
    [
        $users.selectors.getUserFeedIdsByPublicationId,
        getSelectedItems,
        $publications.selectors.getPublicationsMap,
        $feeds.selectors.getFeedsMap,
    ],
    (feedIdsByPubId, groupItems, publications, feeds) => c(
        reduce(
            (opts, [ pubId, feedIds ]) => [
                ...opts,
                pubOptData(publications[ pubId ]),
                ...feedIds.map(id => feedOptData(feeds[ id ])),
            ],
            []
        ),
        toPairs,
        getCurrentPool(feedIdsByPubId),
    )(groupItems),
);

export const makeGetOptions = () => createSelector(getOptions, identity);

export default {
    getSelectedItems,
    makeGetSelectedItems,
    getCurrentValue,
    makeGetCurrentValue,
    getOptions,
    makeGetOptions,
};
