import Link from '@ui/components/link';

import HeaderBtn from '@ui/components/header-btn';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

export default () => (
	<HeaderBtn>
		<Link
			className={ s.link }
			to={{
				isAdmin: false,
				pathname: '/discover',
				state: {}
			}}
		>
			<span className={ m[ 'icon-search' ] } />
		</Link>
	</HeaderBtn>
);
