import { fork, call, cancel, cancelled, take, put, takeLatest } from 'redux-saga/effects'
import { groupActions, createAPISaga } from '@lib/utils/redux';
import { actions } from './';
import api from './api';

const createPublication = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.createPublication, acts.createPublication);

    yield takeLatest(acts.createPublication.request, workerSaga);
};

const deletePublication = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.deletePublication, acts.deletePublication);

    yield takeLatest(acts.deletePublication.request, workerSaga);
};

const getPublication = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getPublication, acts.getPublication);

    yield takeLatest(acts.getPublication.request, workerSaga);
};

const getPublicationBacklog = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getPublicationBacklog, acts.getPublicationBacklog);

    yield takeLatest(acts.getPublicationBacklog.request, workerSaga);
};

const getPublicationsByFeedIds = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getPublicationsByFeedIds, acts.getPublicationsByFeedIds);

    yield takeLatest(acts.getPublicationsByFeedIds.request, workerSaga);
};

const invertForOther = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.invertForOther, acts.invertForOther);

    yield takeLatest(acts.invertForOther.request, workerSaga);
};

const submitImageUrl = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.submitImageUrl, acts.submitImageUrl);

    yield takeLatest(acts.submitImageUrl.request, workerSaga);
};

const updatePublication = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.updatePublication, acts.updatePublication);

    yield takeLatest(acts.updatePublication.request, workerSaga);
};

const uploadImage = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.uploadImage, acts.uploadImage);

    yield takeLatest(acts.uploadImage.request, workerSaga);
};

export default function* () {

    const sagas = [
        createPublication,
        deletePublication,
        getPublication,
        getPublicationBacklog,
        getPublicationsByFeedIds,
        invertForOther,
        submitImageUrl,
        updatePublication,
        uploadImage,
    ];

	for (let saga of sagas) {
		yield fork(saga);
	}
};
