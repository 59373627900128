import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose as c } from 'ramda';

import $app from '@state/app';
import $users from '@state/users';
import $search from '@state/search';
import $socketio from '@state/socketio';
import $auth from '@state/auth';

import AppLayout from '@ui/layouts/app';
import Private from '@ui/components/private';
import Nav from '@ui/components/header/nav';
import Workspace from '@ui/components/workspace';
import Header from '@ui/components/header/main';
import CenterList from '@ui/components/list';
import MenuBtn from '@ui/components/menu-btn';
import HomeBtn from '@ui/components/home-header-btn';
import DiscoverBtn from '@ui/components/discover-header-btn';
import Search from '@ui/components/search';
import Results from './ui/results';

import s from './styles.scss';

export default connect(
	(state, props) => ({
		mobile: $app.selectors.getMobile(state, props),
        isLoggedIn: $auth.selectors.isLoggedIn(state),
        userDomainLoaded: $app.selectors.userDomainLoaded(state),
		accessToken: $auth.selectors.getAccessToken(state, props),
		user: $users.selectors.getLoggedInUser(state, props),
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
		responsive: $app.selectors.getResponsive(state, props),
	}),
	{
		socketConnect: $socketio.selectors.connect,
        getUserDomain: $app.actions.getUserDomainRequest,
	}
)(
	c(
		({
            user,
            isLoggedIn,
            userDomainLoaded,
            accessToken,
            responsive,
            mobile,
            sockets,
            socketConnect,
            getUserDomain,
        }) => {

			useEffect(
                () => {
                    if (isLoggedIn && !userDomainLoaded) {
                        getUserDomain({ userId: user?._id });
                    }
                },
                [
                    isLoggedIn,
                    userDomainLoaded,
                ]
            );

			if (!user) {
				return <div />;
			}

            const inputRef = useRef(null);

			return (
                <AppLayout
                    header={
                        () => (
                            <Header
                                left={
                                    () => (
                                        <>
                                            <MenuBtn />
                                            <Nav />
                                        </>
                                    )
                                }
                                center={
                                    () => (
                                        <div className={ s.center }>
                                            <div className={ s.centerLeft }></div>
                                            <div className={ s.centerCenter }>
                                                <Search inputRef={ inputRef } />
                                            </div>
                                            <div className={ s.centerRight }></div>
                                        </div>
                                    )
                                }
                                right={ () => null }
                            />
                        )
                    }
                    workspace={
                        () => (
                            <Workspace>
                                {
                                    (responsive.md || mobile.visible === 'search') &&
                                        <Results userId={ user._id } />
                                }
                            </Workspace>
                        )
                    }
                />
			);
		}
	)
);
