import Link from '@ui/components/link';

import HeaderBtn from '@ui/components/header-btn';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

export default ({ user }) => {

	if (!user) {
		return <div />;
	}
	
	return (
		<HeaderBtn>
			<Link
				className={ s.link }
				to={{
					pathname: `/@${ user.username }`,
					params: {
						username: user.username,
					}
				}}
			>
				<span className={ m[ 'icon-home3' ] } />
			</Link>
		</HeaderBtn>
	);
};
