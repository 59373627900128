import { createSelector } from '@reduxjs/toolkit';
import {
	append, compose as c, defaultTo, difference, equals, filter, flip, forEach, 
	identity, includes, isNil, lensPath, map, not, nthArg, prop, propEq, reduce, reject,
	transduce, uniq, view,
} from 'ramda';

const self = state => state.feeds;

export const getFeedId = c(prop('feedId'), nthArg(1));

export const getFeedsIds = createSelector(
    self,
    prop('allIds')
);

export const getFeedsMap = createSelector(
    self,
    prop('byId')
);

export const getPublicationId = c(prop('publicationId'), nthArg(1));

export const getFeedIdsByPublicationId = ({ feeds }, { publicationId }) =>
    feeds.idsByPublicationId[ publicationId ];

export const makeGetFeedIdsByPublicationId = () => createSelector(
    getFeedIdsByPublicationId,
    identity
);

export const getFeedById = createSelector(
    [ getFeedId, getFeedsMap ],
    (feedId, feeds) => feeds[ feedId ],
);

export const getPublicationIdByFeedId = createSelector(
    [ getFeedById ],
    feed => feed ? feed.publicationId : undefined
);

export const makeGetFeedById = () => createSelector(getFeedById, identity);

export const getFeedsByPublicationId = createSelector(
    [ getFeedsMap, getFeedIdsByPublicationId ],
    (feeds, feedIds) => c(
        map(flip(prop)(feeds)),
        defaultTo([]),
    )(feedIds),
);

export const makeGetFeedsByPublicationId = () => createSelector(
    getFeedsByPublicationId,
    identity
);

export default {
    getFeedId,
    getFeedsIds,
    getFeedsMap,
    getPublicationId,
    getFeedIdsByPublicationId,
    makeGetFeedIdsByPublicationId,
    getPublicationIdByFeedId,
    getFeedById,
    makeGetFeedById,
    getFeedsByPublicationId,
    makeGetFeedsByPublicationId,
};
