import { compose as c } from 'ramda';
import { connect } from 'react-redux';
import cn from 'classnames/bind';

import $app from '@state/app';
import $users from '@state/users';

import Bookmark from './bookmark';

import s from './styles.scss';

const sx = cn.bind(s);

export default connect(
	(state, props) => ({
		responsive: $app.selectors.getResponsive(state, props),
		bookmarks: $users.selectors.getBookmarks(state, props),
	}),
	{},
)(
	c(
		({ bookmarks, responsive }) => (
			<div className={ sx('root', { mobile: !responsive.md }) }>
				{
					!bookmarks.length &&
						<div className={ s.emptyBookmarks }>
							No bookmarks
						</div>
				}
				{
					!!bookmarks.length && bookmarks.map(b => (
						<Bookmark
							key={ b._id }
							bookmark={ b }
							feedId={ b.feedId }
						/>
					))
				}
			</div>
		),
	)
);
