import { connect } from 'react-redux';
import cn from 'classnames/bind';

import $users from '@state/users';
import $feeds from '@state/feeds';
import $modals from '@state/modal';

import z from '@ui/styles/base.scss';
import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const zx = cn.bind(z);

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
	}),
	{
		openModal: $modals.actions.open,
	},
)(
	({ user, feed, openModal }) => (
		<div className={ s.root }>
			<div className={ s.title }>{ feed.title }</div>
			<div
				className={ s.unsub }
				onClick={
					() => openModal({
						type: 'unsubscribeFeed',
						props: {
							feedId: feed._id,
							userId: user._id
						},
					})
				}
			>
				<span className={ cn(zx('materialOutlined'), sx('close')) }>
                    close_small
                </span>
            </div>
		</div>
	)
);
