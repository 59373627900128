import { connect } from 'react-redux';
import { compose as c } from 'ramda';

import $users from '@state/users';
import $feeds from '@state/feeds';

import Feed from '../feed';

import s from './styles.scss';

export default connect(
	(state, props) => ({
		feeds: $feeds.selectors.makeGetFeedsByPublicationId()(state, props),
		user: $users.selectors.getUser(state, props),
	}),
	{},
)(
	c(
		({ feeds, user }) => feeds.length > 1 ? (
			<ul className={ s.root }>
				{
					feeds.reduce(
						(feedEls, feed) => {

							const subbed = user ? user.subscriptions.includes(feed._id) : false;

							if (feed.aggregate) {
								return feedEls;
							}

							return [
								...feedEls,
								(
									<Feed
										key={ feed._id }
										subbed={ subbed }
										user={ user }
										{ ...feed }
									/>
								),
							];
						},
						[]
					)
				}
			</ul>
		) :
		null,
	)
);
