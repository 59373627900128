import { createSelector } from '@reduxjs/toolkit';
import {
	append, compose, filter, identity, isNil, map, prop, propEq, reject, transduce
} from 'ramda';

const self = state => state.groups;

export const getGroupsMap = createSelector(self, prop('byId'));

export const getGroupsIds = createSelector(self, prop('allIds'));

export const getGroupIdsMapByUserId = createSelector(self, prop('idsByUserId'));

export const getGroupId = (_, { groupId }) => groupId;

export const getGroupByGroupId = createSelector(
    [ getGroupId, getGroupsMap ],
    prop
);

export const getGroupItems = createSelector(
    [ getGroupByGroupId ],
    prop('items')
);

export const getPublicationGroupItems = createSelector(
    [ getGroupItems ],
    filter(propEq('type', 'publication'))
);

export const getPublicationGroupItemIds = createSelector(
    [ getGroupItems ],
    () => ([])/*transduce(
        filter(propEq('type', 'publication')),
        (ids, item) => append(prop('id', item), ids),
        []
    )*/
);

export const getFeedGroupItems = createSelector(
    [ getGroupItems ],
    filter(propEq('type', 'feed'))
);

export const getFeedGroupItemIds = createSelector(
    [ getGroupItems ],
    () => ([])/*transduce(
        filter(propEq('type', 'feed')),
        (ids, item) => append(prop('id', item), ids),
        []
    )*/
);

export const makeGetGroupByGroupId = () => createSelector(getGroupByGroupId, identity);

export const getGroupsByIds = ({ groups }, { groupIds }) => compose(
    reject(isNil),
    pick(groupIds, groups)
);

export const makeGetGroupsByIds = () => createSelector(getGroupsByIds, identity);

export default {
    getGroupsMap,
    getGroupsIds,
    getGroupIdsMapByUserId,
    getGroupId,
    getGroupByGroupId,
    getGroupItems,
    getPublicationGroupItems,
    getPublicationGroupItemIds,
    getFeedGroupItems,
    getFeedGroupItemIds,
    makeGetGroupByGroupId,
    getGroupsByIds,
    makeGetGroupsByIds,
};
