import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import $modal from '@state/modal';
import $users from '@state/users';
import $groups from '@state/groups';

import Modal from '../templates/main';

import n from '@ui/styles/icomoon.css';
import s from './styles.scss';
import m from '../../styles.scss';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
		loggedInUser: $users.selectors.getLoggedInUser(state, props),
	}),
	{
		closeModal: $modal.actions.close,
		deleteGroup: $groups.actions.deleteGroupRequest,
	}
)(
	c(
		({ group, loggedInUser, modalOpen, closeModal, deleteGroup }) => (
			<Modal
				header={
					() => (
						<span>
							Delete Group
						</span>
					)
				}
                body={
                    () => (
                        <div className={ s.body }>
                            <span className={ n[ 'icon-folder' ] }/>
                            <div className={ s.groupName }>
                                { group.name }
                            </div>
                        </div>
                    )
                }
				footer={
					() => (
						<>
							<button
								className={ mx('btn', 'btnCancel') }
								onClick={ closeModal }
							>
								No
							</button>
							<button
								className={ mx('btn', 'btnConfirm') }
								onClick={
									() => {
										deleteGroup({
											userId: loggedInUser._id,
											groupId: group._id,
										});
										closeModal();
									}
								}
							>
								Yes
							</button>
						</>
					)
				}
			/>
		)
	)
);
