import { fork, call, cancel, cancelled, take, put, takeLatest } from 'redux-saga/effects'
import { getHistory } from '@lib/history-manager';
import { groupActions, createAPISaga } from '@lib/utils/redux';
import axiosClient, { defaultsFromConf } from '@lib/axios-client';
import socketio from '@lib/socketio';
import { actions } from './';
import api from './api';
import config from './config.json';

const SAVE_TO_LOCAL_STORAGE = process.env.NODE_ENV === 'development';

const acts = groupActions(actions);

const authorize = function* ({ payload, ctx }) {

	try {
		const { username, passphrase } = payload;
		const url = `/@${ username }`;

		const res = yield call(ctx.api, { username, passphrase });

        socketio(res.data.user._id);

		yield put(ctx.actions.loginSuccess(res.data));

        if (ctx.ls) {
            const { accessToken, user } = res.data;
            const loginStateStr = JSON.stringify({ accessToken, user });
            localStorage.setItem('loginState', loginStateStr);
        }

        const history = getHistory();
		history.push(url);

	} catch (e) {

		yield put(ctx.actions.loginFailure({ error: e.message }));

	} finally {
		if (yield cancelled()) {
		}
	}
};

const login = function* () {

	while (true) {

		const reqAction = yield take(actions.loginRequest.type);
		const { username, passphrase } = reqAction.payload;

		const task = yield fork(authorize, {
			ctx: {
                actions: {
                    loginRequest: actions.loginRequest,
                    loginSuccess: actions.loginSuccess,
                    loginFailure: actions.loginFailure,
                },
                ls: SAVE_TO_LOCAL_STORAGE,
                api: api.login,
            },
			payload: { username, passphrase },
		});

		const action = yield take([
			actions.logoutRequest,
			actions.loginFailure,
		]);

		if (action.type === actions.logoutRequest.type) {
			yield cancel(task);
		}
	}
};

const unsetLoginState = () => localStorage.removeItem('loginState');

const redirectLogin = () => {
    const history = getHistory();
    history.push('/login');
}

const logout = function* () {

    const conf = config.actions?.async?.logout;
    const api = axiosClient(defaultsFromConf(conf));

    const worker = function* (action) {

        try {
            let response = yield call(api);

            if (!response.success) {
                yield put(acts.logout.failure(response.data));

            } else {

                yield call(unsetLoginState);
                yield call(redirectLogin);

                yield put(acts.logout.success(
                    response.data,
                    { req: action.payload }
                ));


            }
        } catch (e) {
            yield put(acts.logout.failure({ message: e.toString() }));
        }
    };

    yield takeLatest(acts.logout.request, worker);
};

const setAuthToken = accessToken => {

    const loginState = JSON.parse(localStorage.getItem('loginState'));

    localStorage.setItem(
        'loginState',
        JSON.stringify({ ...loginState, accessToken })
    );
};

const refresh = function* () {

    const conf = config.actions?.async?.refresh;
    const api = axiosClient(defaultsFromConf(conf));

    const worker = function* (action) {

        try {
            let response = yield call(api, action.payload);

            if (!response.success) {
                yield put(acts.refresh.failure(response.data));

            } else {

                yield call(setAuthToken, response.data.accessToken);

                yield put(acts.refresh.success(
                    response.data,
                    { req: action.payload }
                ));

                return response.data;
            }
        } catch (e) {
            yield put(acts.refresh.failure({ message: e.toString() }));
        }
    };

    yield takeLatest(acts.refresh.request, worker);
};

export default function* () {

	for (let saga of [ login, logout, refresh ]) {
		yield fork(saga);
	}
};
