import { createReducer } from '@lib/utils/redux';
import config from '@nl/groups/config.json';
import actions from './actions';

const reducers = {
    idsByUserId: {
		createGroupSuccess: (state, action) => {
            const { payload } = action;
            const { group } = payload;
			if (!(group.userId in state)) {
				state[ group.userId ] = [];
			}
			if (!state[ group.userId ].includes(group._id)) {
				state[ group.userId ].push(group._id);
			}
		},
        getGroupsSuccess: (state, action) => {
            const { payload } = action;
            const { group } = payload;
			if (groups && groups.length) {
				const userId = groups[ 0 ].user.id;
			}
		},
	},
};

const reducer = createReducer(config, actions, reducers);

export default reducer;
