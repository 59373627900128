import runtime from '@lib/client-runtime';

if (process.env.NODE_ENV === 'development' && module.hot) {

	module.hot.accept('@ui/components/app', () => {
        runtime();
	});
}

if (['complete', 'loaded', 'interactive'].includes(document.readyState) && document.body) {
    runtime();
} else {
    window.addEventListener('DOMContentLoaded', runtime, false);
}
