import USER_STD_FRAG from '@nl/users/graphql/fragments/user-std.gqlf';
import STORY_STD_FRAG from '@nl/stories/graphql/fragments/story-std.gqlf';

const USER_STD = '...userStd';
const STORY_STD = '...storyStd';
const USER_STORIES = `
user { ...userStd }
stories { ...storyStd }
`;

const USER_STORY_FRAGS = `
${ USER_STD_FRAG }
${ STORY_STD_FRAG }
`;

const getUser = (fields = USER_STD, fragments = USER_STD_FRAG) => `
query GetUser($username: String, $email: String, $_id: ID) {
    user: getUser(username: $username, email: $email, _id: $_id) {
        ${ fields }
    }
}
${ fragments }
`;

const setCurrentView = (fields = USER_STORIES, fragments = USER_STORY_FRAGS) => `
mutation SetCurrentView($userId: ID!, $socketId: ID!, $input: CurrentViewInput!) {
    setViewResult: setCurrentView(userId: $userId, socketId: $socketId, input: $input) {
        ${ fields }
    }
}
${ fragments }
`;

const getPrevFeedPage = (fields = STORY_STD, fragments = STORY_STD_FRAG) => `
query GetPrevFeedPage($userId: ID!, $storyId: ID!, $pageOpts: PageOptsInput) {
    stories: getPrevFeedPage(userId: $userId, storyId: $storyId, pageOpts: $pageOpts) {
        ${ fields }
    }
}
${ fragments }
`;

const verifyEmail = (fields = USER_STD, fragments = USER_STD_FRAG) => `
query VerifyEmail($email: String!, $token: String!) {
    user: verifyEmail(email: $email, token: $token) {
        ${ fields }
    }
}
${ fragments }
`;

const addBookmark = (fields = USER_STD, fragments = USER_STD_FRAG) => `
mutation AddBookmark($userId: ID!, $storyId: ID!) {
    user: addBookmark(userId: $userId, storyId: $storyId) {
        ${ fields }
    }
}
${ fragments }
`;

const removeBookmark = (fields = USER_STD, fragments = USER_STD_FRAG) => `
mutation removeBookmark($userId: ID!, $storyId: ID!) {
    user: removeBookmark(userId: $userId, storyId: $storyId) {
        ${ fields }
    }
}
${ fragments }
`;

const subscribe = (fields = USER_STORIES, fragments = USER_STORY_FRAGS) => `
mutation Subscribe($userId: ID!, $socketId: ID!, $input: SubscribeInput!) {
    subscribeResult: subscribe(userId: $userId, socketId: $socketId, input: $input) {
        ${ fields }
    }
}
${ fragments }
`;

const unsubscribe = (fields = USER_STORIES, fragments = USER_STORY_FRAGS) => `
mutation Unsubscribe($userId: ID!, $socketId: ID!, $publicationId: ID!) {
    unsubscribeResult: unsubscribe(userId: $userId, socketId: $socketId, publicationId: $publicationId) {
        ${ fields }
    }
}
${ fragments }
`;

const updateSettings = (fields = USER_STD, fragments = USER_STD_FRAG) => `
mutation UpdateSettings($userId: ID!, $settings: SettingsInput!) {
    user: updateSettings(userId: $userId, settings: $settings) {
        ${ fields }
    }
}
${ fragments }
`;

export default {
    getUser,
    setCurrentView,
    verifyEmail,
    addBookmark,
    removeBookmark,
    subscribe,
    unsubscribe,
    getPrevFeedPage,
    updateSettings,
};
