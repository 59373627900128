import Select, { components } from 'react-select';
import { compose as c, omit } from 'ramda';
import cn from 'classnames/bind';

import Option from '@ui/components/react-select/option';
import MultiValueLabel from '@ui/components/react-select/multi-value-label';

import s from './styles.scss';

const MultiValueRemove = props => (
	<components.MultiValueRemove {...props }>
		<span>×</span>
	</components.MultiValueRemove>
);

const ClearIndicator = props => (
	<components.ClearIndicator { ...props }>
		<span className={ s.clearIndicator }>×</span>
	</components.ClearIndicator>
);

export default c(
	({
		options,
		currentValue,
		onChangeFn,
	}) => (
		<div className={ s.root }>
			<Select
				isMulti
				closeMenuOnSelect={ false }
				defaultMenuIsOpen={ true }
				options={ options }
				value={ currentValue }
				onChange={ onChangeFn }
				hideSelectedOptions={ false }
				components={{
					ClearIndicator,
					MultiValueLabel,
					MultiValueRemove,
					Option,
				}}
				styles={{
					clearIndicator: base => ({
						...base,
						fontSize: '18px',
						cursor: 'pointer',
					}),
					control: base => ({
						...base,
						border: 'none',
						boxShadow: 'none',
					}),
					container: base => ({
						...base,
						width: 270,
						border: '1px solid #efefef',
						borderRadius: '5px',
					}),
					option: base => ({
						...base,
						display: 'flex',
						height: '25px',
						padding: '0 12px',
					}),
					multiValue: base => ({
						...base,
						backgroundColor: 'white',
					}),
					multiValueRemove: base => ({
						...omit(
							[
								':hover',
								'color',
								'backgroundColor'
							],
							base
						),
						color: '#777',
						cursor: 'pointer',
					}),
				}}
			/>
		</div>
	)
);
