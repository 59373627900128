import { fork } from 'redux-saga/effects';

import { saga as app } from '@state/app';
import { saga as auth } from '@state/auth';
import { saga as users } from '@state/users';
import { saga as search } from '@state/search';
import { saga as socketio } from '@state/socketio';
import { saga as feed } from '@state/feed';
import { saga as groups } from '@state/groups';

const sagas = {
    app,
    auth,
    users,
    search,
    socketio,
    feed,
    groups,
};

export default function* () {

    const pairs = Object.entries(sagas);

    for (let [ modId, saga ] of pairs) {
        try {
            yield fork(saga);
        } catch (e) {
            console.log(`Redux Saga: Error in module [${ modId }]`, e);
        }
    }
};
