import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';

export default ({ type, className, content }) => {

    return (
        <>
            { type === 'material-ui' &&
                <span className={ className }>
                    { content }
                </span>
            }
            { type === 'icomoon' &&
                <span className={ className } />
            }
            { type === 'font-awesome' &&
                <Fa icon={ `fa-solid ${ className }`} />
            }
        </>
    );
};
