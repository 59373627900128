import { connect } from 'react-redux';

import $feeds from '@state/feeds';
import $publications from '@state/publications';

import Image from '@ui/components/image';
import s from './styles.scss';

const FeedItem = connect(
	(state, props) => ({
		publication: $publications.selectors.makeGetPublication()(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
	})
)(
	({ title, publication, logoSrc }) => (
		<>
			<span className={ s.imgCont }>
				<Image
					className={ s.groupItemImg }
					src={ logoSrc }
					defaultSrc='/imgs/newspaper.png'
				/>
			</span>
			<span className={ s.groupLabel} >{ title }</span>
		</>
	)
);

const PublicationItem = ({ name, logoSrc }) => (
	<>
		<span className={ s.imgCont }>
			<Image
				className={ s.groupItemImg }
				src={ logoSrc }
				defaultSrc='/imgs/newspaper.png'
			/>
		</span>
		<span className={ s.groupLabel} >{ name }</span>
	</>
);

const GroupItem = ({ feed, publication, logoSrc }) => (
	<li className={ s.groupItem }>
		{ feed && <FeedItem { ...feed } /> }
		{ publication && <PublicationItem { ...publication } logoSrc={ logoSrc } /> }
	</li>
);

export default connect(
	(state, props) => ({
		feed: $feeds.selectors.makeGetFeedById()(state, props),
		publication: $publications.selectors.makeGetPublication()(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
	}),
	{},
)(GroupItem);
