import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';

import app from '@state/app';

import s from './styles.scss';

export default connect(
	(state, props) => ({
	}),
	{
		openMenu: app.actions.openMenu,
	}
)(
	c(
		({ openMenu }) => (
			<div className={ s.root } onClick={ openMenu }>
				<div className={ s.iconCont }>
					<Fa icon='fa-solid fa-bars' />
				</div>
			</div>
		)
	)
);
