import { useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { compose as c, reduce, toPairs } from 'ramda';
import cn from 'classnames/bind';

import { bindThis } from '@lib/utils/react';

import $modal from '@state/modal';
import $socketio from '@state/socketio';
import $users from '@state/users';
import $feeds from '@state/feeds';
import $publications from '@state/publications';
import $groups from '@state/groups';
import $userGroups from '@state/user-groups';

import Modal from '../templates/main';

import s from './styles.scss';
import m from '../../styles.scss';
import i from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);
const ix = cn.bind(i);

const PubRow = connect(
	(state, props) => ({
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
		pub: $publications.selectors.getPublication(state, props),
	}),
	{
	}
)(
	c(
		({ logoSrc, pub, selected, onClick }) => (
			<div
				className={ sx('pubRow') }
				onClick={ onClick }
			>
				<span
					className={
						cn(
							sx(
								'check',
								{
									active: selected
								}
							),
							ix('icon-checkmark')
						)
					}
				/>
				<span className={ s.pubIconCont }>
					<img src={ logoSrc } />
				</span>
				<span className={ s.pubName }>
					{ pub.name }
				</span>
			</div>
		)
	)
);

const FeedRow = connect(
	(state, props) => ({
		pub: $publications.selectors.getPublication(state, props),
		feed: $feeds.selectors.makeGetFeedById()(state, props),
	}),
	{
	}
)(
	c(
		({ feed, selected, select }) => (
			<li
				key={ feed._id }
				className={
					sx('feedRow')
				}
				onClick={
					() => select('feed', feed._id)
				}
			>
				<span
					className={
						cn(
							sx(
								'check',
								{
									active: selected[ feed._id ] 
								}
							),
							ix('icon-checkmark')
						)
					}
				/>
				<span className={ s.feedTitle }>
					{ feed.title }
				</span>
			</li>
		)
	)
);

const PubsAndFeeds = c(
	({
		all,
		select,
		selected,
		subscriptions,
	}) => (
		<div className={ sx('pubsAndFeedsCont') }>
			<div className={ sx('pubsAndFeeds') }>
				{
					subscriptions.map(
						({ publicationId, pick, feedIds }) => (
							<div key={ publicationId } className={ s.pubAndFeeds }>
								<PubRow
									publicationId={ publicationId }
									selected={ selected[ publicationId ] }
									onClick={
										() => {
											select('publication', publicationId);
										}
									}
								/>
								{
									pick && (
										<ul className={ sx('list', 'feedsList') } >
											{
												feedIds.map(feedId => (
													<FeedRow
														key={ feedId }
														feedId={ feedId }
														selected={ selected }
														select={ select }
													/>
												))
											}
										</ul>
									)
								}
							</div>
						),
					)
				}
			</div>
		</div>
	)
);

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
		modalProps: $modal.selectors.getProps(state, props),
		userGroups: $userGroups.selectors.getGroups(state, props),
		userGroupsOpts: $userGroups.selectors.getUserGroupOpts(state, props),
		all: $userGroups.selectors.groupsPubsFeeds(state, props),
		subscriptions: $users.selectors.getSubscriptions(state, props),
	}),
	{
		updateGroup: $groups.actions.updateGroupRequest,
		getUserGroups: $groups.actions.getGroupsRequest,
		subscribe: $users.actions.subscribeRequest,
		closeModal: $modal.actions.close,
	},
)(
	c(
		({ user, group, all, subscriptions, sockets, closeModal, updateGroup }) => {

			const [ groupItems, setGroupItems ] = useState(group.items);

			const [ selected, setSelected ] = useState(
				c(
					reduce(
						(selectedIds, { publicationId, pick, feedIds }) => {
							
							const pubSelected = !!groupItems.find(
								i => i.id === publicationId
							);

							let feedsSelected = {};

							if (pick) {
								feedsSelected = feedIds.reduce(
									(sel, feedId) => ({
										...sel,
										[ feedId ]: !!groupItems.find(
											i => i.id === feedId,
										),
									}),
									{}
								);
							}

							return {
								...selectedIds,
								[ publicationId ]: pubSelected,
								...feedsSelected,
							};
						},
						{}
					)
				)(subscriptions)
			);

			const select = (type, id) => {

				switch (type) {

					case 'publication':

						if (selected[ id ]) {

							setGroupItems(groupItems.filter(i => i.id !== id));
							setSelected({ ...selected, [ id ]: false });

						} else {

							const pubFeedIds = subscriptions.find(
								s => s.publicationId === id
							).feedIds;

							setGroupItems([
								...groupItems.filter(
									i => !pubFeedIds.find(feedId => i.id === feedId)
								),
								{ type, id },
							]);

							const pubSub = subscriptions.find(
								s => s.publicationId === id
							);

							let feedsSelected = {};

							if (pubSub.pick) {
								feedsSelected = pubFeedIds.reduce(
									(sel, feedId) => ({
										...sel,
										[ feedId ]: false,
									}),
									{}
								);
							}

							setSelected({
								...selected,
								[ id ]: true,
								...feedsSelected,
							});
						}
						break;

					case 'feed':

						if (selected[ id ]) {

							setGroupItems(groupItems.filter(i => i.id !== id));
							setSelected({ ...selected, [ id ]: false });

						} else {
							
							setGroupItems([ ...groupItems, { type, id } ]);
							setSelected({ ...selected, [ id ]: true });
						}
						break;
				}
			};

			return (
				<Modal
					header={
						() => (
							<span>
								Edit Group
							</span>
						)
					}
					body={
						() => (
                            <div className={ s.body }>
                                <div className={ s.groupName }>
                                    { group.name }
                                </div>
                                <PubsAndFeeds
                                    group={ group }
                                    all={ all }
                                    select={ select }
                                    selected={ selected }
                                    subscriptions={ subscriptions }
                                />
                            </div>
						)
					}
					footer={
						() => (
							<>
								<button
									onClick={ closeModal }
									className={ mx('btn', 'btnCancel') }
								>
									Cancel
								</button>
								<button
									onClick={
										() => {
											updateGroup({
												userId: user._id,
												groupId: group._id,
												socketId: sockets[ '/poller' ],
												input: {
													name: group.name,
													items: groupItems,
												},
											});
											closeModal();
										}
									}
									className={ mx('btn', 'btnConfirm') }
								>
									Save
								</button>
							</>
						)
					}
				/>
			);
		},
	)
);
