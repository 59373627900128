import { forEach, map, prop } from 'ramda';
import { createReducer } from '@lib/utils/redux';
import config from '@nl/stories/config.json';
import actions from './actions';

import { actions as app } from '@state/app';
import { actions as users } from '@state/users';
import { actions as groups } from '@state/groups';
import { actions as feed } from '@state/feed';

const reducerImports = {
    app,
    users,
    groups,
    feed,
};

const reducers = {
	byId: {
		'app.getUserDomainSuccess': (state, action) => {

            const { userDomainResult: { stories }} = action.payload;

			forEach(
				story => {
					state[ story._id ] = story;
				},
				stories
			);
		},
		'users.setCurrentViewSuccess': (state, action) => {

			const { setViewResult: { stories }} = action.payload;

			forEach(
				story => {
					state[ story._id ] = story;
				},
				stories
			);
		},
		'users.subscribeSuccess': (state, action) => {

            const { subscribeResult: { stories }} = action.payload;

			if (Array.isArray(stories)) {
				forEach(
					story => {
						state[ story._id ] = story;
					},
					stories
				);
			}
		},
		'users.unsubscribeSuccess': (state, action) => {

            const { unsubscribeResult: { stories }} = action.payload;

			if (Array.isArray(stories)) {
				forEach(
					story => {
						state[ story._id ] = story;
					},
					stories
				);
			}
		},
		'users.getPrevFeedPageSuccess': (state, action) => {

            const stories = action.payload?.stories;

            if (Array.isArray(stories)) {
				forEach(
					story => {
						state[ story._id ] = story;
					},
					stories
				);
			}
        },
		'groups.updateGroupSuccess': (state, action) => {

            const { groupUpdateResult: { stories } } = action.payload;

			if (Array.isArray(stories)) {
				forEach(
					story => {
						state[ story._id ] = story;
					},
					stories
				);
			}
		},
		'feed.nextPageSuccess': (state, action) => {

            const { stories } = action.payload;

			forEach(
				story => {
					state[ story._id ] = story;
				},
				stories
			);
		},
        'feed.clearStory': (state, action) => {
            delete state[ storyId ];
        },
        'feed.storyFromSocket': (state, action) => {

            const story = action.payload?.story;

            if (story) {
                state[ story._id ] = story;
            }
        },
	},
	allIds: {
		'app.getUserDomainSuccess': (state, action) => {

            const { userDomainResult: { stories }} = action.payload;

			const storyIds = map(prop('_id'), stories);
			const ids = new Set([ ...state, ...storyIds ]);

			state.splice(0, state.length, ...ids);
		},
		'users.setCurrentViewSuccess': (state, action) => {

			const { setViewResult: { stories }} = action.payload;
			const storyIds = map(prop('_id'), stories);
			const ids = new Set([ ...state, ...storyIds ]);

			state.splice(0, state.length, ...ids);
		},
		'users.subscribeSuccess': (state, action) => {

            const { subscribeResult: { stories }} = action.payload;

			if (Array.isArray(stories)) {
				const storyIds = map(prop('_id'), stories);
				const ids = new Set([ ...state, ...storyIds ]);

				state.splice(0, state.length, ...ids);
			}
		},
		'users.unsubscribeSuccess': (state, action) => {

            const { unsubscribeResult: { stories }} = action.payload;

			if (Array.isArray(stories)) {
				const storyIds = map(prop('_id'), stories);
				const ids = new Set([ ...state, ...storyIds ]);

				state.splice(0, state.length, ...ids);
			}
		},
        'users.getPrevFeedPageSuccess': (state, action) => {

            const stories = action.payload?.stories;

            if (Array.isArray(stories)) {
				const storyIds = map(prop('_id'), stories);
				const ids = new Set([ ...state, ...storyIds ]);

				state.splice(0, state.length, ...ids);
            }
        },
		'groups.updateGroupSuccess': (state, action) => {

            const { groupUpdateResult: { stories } } = action.payload;

			if (Array.isArray(stories)) {
				const storyIds = map(prop('_id'), stories);
				const ids = new Set([ ...state, ...storyIds ]);

				state.splice(0, state.length, ...ids);
			}
		},
		'feed.nextPageSuccess': (state, action) => {

            const { stories } = action.payload;

			const storyIds = map(prop('_id'), stories);
			const ids = new Set([ ...state, ...storyIds ]);

			state.splice(0, state.length, ...ids);
		},
        'feed.clearStory': (state, action) => {
            state.splice(state.indexOf(storyId), 1);
        },
        'feed.storyFromSocket': (state, action) => {

            const story = action.payload?.story;

            if (story) {
                const ids = new Set([ ...state, ...story._id ]);
                state.splice(0, state.length, ...ids);
            }
        },
	}
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
