import { useEffect } from 'react';
import { connect } from 'react-redux';
import $socketio from '@state/socketio';
import $users from '@state/users';
import $auth from '@state/auth';
import getSocketio from '@lib/socketio';

export default connect(
	(state, props) => ({
        user: $users.selectors.getLoggedInUser(state, props),
		accessToken: $auth.selectors.getAccessToken(state, props),
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
	}),
	{
		socketConnect: $socketio.actions.connect,
		socketDisconnect: $socketio.actions.disconnect,
	}
)(
	({ user, accessToken, sockets, socketConnect, children }) => {

		useEffect(
			() => {
                const socketio = getSocketio();

				if (socketio && user) {
                    if (!sockets['/poller']) {
                        if (accessToken) {
                            socketConnect({ nsp: '/poller', accessToken });
                        }
                    }
                }
			},
            [ user, sockets, accessToken ]
		);

        return <>{ children }</>;
	}
);
