import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

const self = state => state.stories;

export const getStoryIds = createSelector(self, prop('allIds'));

export const getStoriesMap = createSelector(self, prop('byId'));

export const getStories = createSelector(
    [
        getStoryIds,
        getStoriesMap,
    ],
    (ids, storiesMap) => ids.map(id => storiesMap[ id ])
);

export default {
    getStoryIds,
    getStoriesMap,
    getStories,
};
