import createGraphQLAPI from '@lib/utils/graphql-api';
import config from '@nl/publications/config.json';
import createPublication from '@nl/publications/graphql/queries/create-publication.gqlq';
import deletePublication from '@nl/publications/graphql/queries/delete-publication.gqlq';
import getPublication from '@nl/publications/graphql/queries/get-publication.gqlq';
import getPublicationBacklog from '@nl/publications/graphql/queries/get-publication-backlog.gqlq';
import getPublicationsByFeedIds from '@nl/publications/graphql/queries/get-publications-by-feed-ids.gqlq';
import invertForOther from '@nl/publications/graphql/queries/invert-for-other.gqlq';
import submitImageUrl from '@nl/publications/graphql/queries/submit-image-url.gqlq';
import updatePublication from '@nl/publications/graphql/queries/update-publication.gqlq';
import uploadImage from '@nl/publications/graphql/queries/upload-image.gqlq';
import pubStd from '@nl/publications/graphql/fragments/pub-std.gqlf';

const gql = {
    queries: {
        createPublication,
        deletePublication,
        getPublication,
        getPublicationBacklog,
        getPublicationsByFeedIds,
        invertForOther,
        submitImageUrl,
        updatePublication,
        uploadImage,
    },
    fragments: {
        pubStd,
    },
};

const api = createGraphQLAPI(config, gql);

export default api;
