import ReactModal from 'react-modal';
import { connect } from 'react-redux';

import $modal from '@state/modal';

ReactModal.setAppElement('#root');

//import SubscribeFeed from 'modalSubscribeFeed/jsx';
import SubscribePublication from './ui/subscribe-publication';
import UnsubscribeFeed from './ui/unsubscribe-feed';
import UnsubscribePublication from './ui/unsubscribe-publication';
import CreateGroup from './ui/create-group';
import DeleteGroup from './ui/delete-group';
import RemoveBookmark from './ui/remove-bookmark';
import GroupEditMembers from './ui/edit-group-members';

//import JoinModal from '../Join';
//import ForgotPassphraseModal from '../ForgotPassphrase';
//import ForgotUsernameModal from '../ForgotUsername';

const RootModal = ({ type, props }) => {

	switch (type) {

		case 'subscribeFeed':
			return null;//<SubscribeFeed { ...props } />;

		case 'subscribePublication':
			return <SubscribePublication { ...props } />;

		case 'unsubscribeFeed':
			return <UnsubscribeFeed { ...props } />;

		case 'unsubscribePublication':
			return <UnsubscribePublication { ...props } />;

		case 'createGroup':
			return <CreateGroup { ...props } />;

		case 'deleteGroup':
			return <DeleteGroup { ...props } />;

		case 'groupEditMembers':
			return <GroupEditMembers { ...props } />;

		case 'removeBookmark':
			return <RemoveBookmark { ...props } />;

		//case 'join': return <JoinModal { ...props } />;
		//case 'forgotPassphrase': return <ForgotPassphraseModal { ...props } />;
		//case 'forgotUsername': return <ForgotUsernameModal { ...props } />;

		default:
			return null;
	}
};

export default connect(
	(state, props) => ({
		type: $modal.selectors.getType(state, props),
		props: $modal.selectors.getProps(state, props),
	})
)(RootModal);
