import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import { selectors as appSels } from '@state/app';

import s from './styles.scss';
import logoUrl from '@nl/assets/images/nl-100.png';

const sx = cn.bind(s);

export default connect(
	(state, props) => ({
		mobile: appSels.getMobile(state, props),
		responsive: appSels.getResponsive(state, props),
	}),
	{
	}
)(
	c(
		({ responsive }) => (
			<div className={ s.root }>
				{
					//responsive.md &&
					false &&
					<img className={ s.logo } src={ logoUrl } />
				}
				<span className={ s.title }>NewsLab</span>
			</div>
		)
	)
);
