import { createSelector } from '@reduxjs/toolkit';

const self = state => state.search;

export const getSearchResults = (state) => {
    const { publications, search } = state;
    const r = search.reduce(
    (all, id) => id in publications.byId ?
        [ ...all, publications.byId[ id ] ] :
        all,
    []);
    return r;
};

export default {
    getSearchResults,
};
