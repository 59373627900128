import GROUP_STD_FRAG from '@nl/groups/graphql/fragments/group-std.gqlf';
import STORY_STD_FRAG from '@nl/stories/graphql/fragments/story-std.gqlf';

const GROUP_STD = '...groupStd';

const GROUP_STORIES = `
group { ...groupStd }
stories { ...storyStd }
`;

const GROUP_STORY_FRAGS = `
${ GROUP_STD_FRAG }
${ STORY_STD_FRAG }
`;

const createGroup = (fields = GROUP_STD, fragments = GROUP_STD_FRAG) => `
mutation CreateGroup($input: GroupInput!) {
    group: createGroup(input: $input) {
        ${ fields }
    }
}
${ fragments }
`;

const getGroups = (fields = GROUP_STD, fragments = GROUP_STD_FRAG) => `
query GetGroups($userId: ID!) {
    groups: getGroups(userId: $userId) {
        ${ fields }
    }
}
${ fragments }
`;

const updateGroup = (fields = GROUP_STORIES, fragments = GROUP_STORY_FRAGS) => `
mutation UpdateGroup($userId: ID!, $groupId: ID!, $socketId: ID!, $input: GroupUpdateInput!) {
    groupUpdateResult: updateGroup(userId: $userId, groupId: $groupId, socketId: $socketId, input: $input) {
        ${ fields }
    }
}
${ fragments }
`;

const deleteGroup = (fields = GROUP_STD, fragments = GROUP_STD_FRAG) => `
mutation DeleteGroup($userId: ID!, $groupId: ID!) {
    group: deleteGroup(userId: $userId, groupId: $groupId) {
        ${ fields }
    }
}
${ fragments }
`;

const addItem = (fields = GROUP_STD, fragments = GROUP_STD_FRAG) => `
mutation AddItem($userId: ID!, $groupId: ID!, $item: GroupItemInput!) {
    groups: addItem(userId: $userId, groupId: $groupId, item: $item) {
        ${ fields }
    }
}
${ fragments }
`;

export default {
    createGroup,
    getGroups,
    updateGroup,
    deleteGroup,
    addItem,
};
