import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';

import $app from '@state/app';
import $users from '@state/users';
import $publications from '@state/publications';
import $socketio from '@state/socketio';
import $feeds from '@state/feeds';
import $modal from '@state/modal';
import $tags from '@state/tags';

import Feeds from '../feeds';
import Image from '@ui/components/image';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

const Tag = connect(
	(state, props) => ({
		tag: $tags.selectors.getTag(state, props),
	}),
	{
	}
)(
	c(
		({ tag, tagId }) => {
			if (!tag) {
				console.log(tagId, 'not found');
				return null;
			}
			return <span className={ s.tag }>#{tag.name }</span>
		}
	)
);

export default connect(
	(state, props) => ({
		responsive: $app.selectors.getResponsive(state, props),
		user: $users.selectors.getUser(state, props),
		socketIds: $socketio.selectors.getSocketIdsByNsp(state, props),
		hasSub: $users.selectors.hasPublicationSubs(state, props),
		hasPubSubOnly: $users.selectors.hasPublicationSubOnly(state, props),
		publication: $publications.selectors.makeGetPublication()(state, props),
		feeds: $feeds.selectors.makeGetFeedsByPublicationId()(state, props),
		subbedFeeds: $users.selectors.getUserPublicationFeeds(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
	}),
	{
		openModal: $modal.actions.open,
		subscribe: $users.actions.subscribeRequest,
	},
)(
	c(
		({
			user,
			socketIds,
			publication,
			hasSub,
			hasPubSubOnly,
			subbedFeeds,
			feeds,
			logoSrc,
			openModal,
			responsive,
			subscribe,
		}) => {

            const subText = hasSub ? 'Subscribed' : 'Subscribe';

			const subBtn = (
				<button
					className={ sx('subBtn', { mobileSubBtn: !responsive.md }) }
					onClick={
						() => {

							if (hasPubSubOnly) {
								return openModal({
									type: 'unsubscribePublication',
									props: {
										publicationId: publication._id,
										userId: user._id,
									},
								});
							}

							if (feeds.length === 1) {
								return subscribe({
									userId: user._id,
									socketId: socketIds[ '/poller' ],
									input: {
										publicationId: publication._id,
										feeds: feeds.map(f => ({ feedId: f._id })),
										groupId: null,
										pick: false,
									}
								});
							}

							openModal({
								type: 'subscribePublication',
								props: {
									publicationId: publication._id,
									userId: user._id,
									nsp: '/poller',
									hasSub,
									subbedFeeds,
									publication,
								},
							})
						}
					}
				>
					{ subText }
					{
						hasSub &&
							<span
								className={ cn(sx('icon'), mx('icon-checkmark')) }
							/>
					}
				</button>
			);

			return (
				<li className={ s.root }>
					{
						responsive.md ? (
							<>
								<aside>
									<Image
										className={ sx('logo') }
										src={ logoSrc }
										defaultSrc='/imgs/newspapercover.png'
									/>
								</aside>
								<section className={ s.main }>
									<article className={ s.mainArticle }>
										<header className={ sx('header') }>
											<div className={ s.headerTop }>
												<h3 className={ sx('title') }>
													{ publication.name }
												</h3>
												{ subBtn }
											</div>
											<div className={ s.headerBottom }>
												<a href={ publication.url } target='_blank'>
													{ publication.url.replace(/^https?:\/\//, '') }
												</a>
											</div>
										</header>
										<p>
											{ publication.description }
										</p>
										<div className={ s.tags }>
											{
												publication.tags.map(
													t => <Tag key={ t } tagId={ t } />
												)
											}
										</div>
									</article>
								</section>
							</>
						) : (
							<article className={ sx('mainArticle', 'mobile') }>
								<header className={ sx('mobileHeader') }>
									<aside>
										<Image
											className={ sx('logo', 'mobileLogo') }
											src={ logoSrc }
											defaultSrc='/imgs/newspapercover.png'
										/>
									</aside>
									<section className={ s.mobileHeaderMain }>
										<div
											className={
												sx(
													'headerTop',
													{ mobile: !responsive.md }
												)
											}
										>
											<h3 className={ sx('title', 'mobileTitle') }>
												{ publication.name }
											</h3>
											<a
												className={ s.mobilePubLink }
												href={ publication.url }
												target='_blank'
											>
												{ publication.url.replace(/^https?:\/\//, '') }
											</a>
										</div>
										<div className={ s.headerBottom }>
										</div>
									</section>
								</header>
								<p>
									{ publication.description }
								</p>
								<div className={ s.tags }>
									{
										publication.tags.map(
											t => <Tag key={ t._id } tagId={ t._id } />
										)
									}
								</div>
								{ subBtn }
							</article>
						)
					}
				</li>
			);
		}
	)
);
