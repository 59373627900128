import { useEffect } from 'react';
import { connect } from 'react-redux';
import $auth from '@state/auth';
import $users from '@state/users';
import $app from '@state/app';

export default connect(
    (state, props) => ({
        loggedInUser: $users.selectors.getLoggedInUser(state),
        loginDetermined: $auth.selectors.loginDetermined(state),
        isRefreshing: $auth.selectors.isRefreshing(state),
        isLoggedIn: $auth.selectors.isLoggedIn(state),
        userDomainLoaded: $app.selectors.userDomainLoaded(state),
    }),
    {
        refresh: $auth.actions.refreshRequest,
        getUserDomain: $app.actions.getUserDomainRequest,
    }
)(
    ({
        loginDetermined,
        userDomainLoaded,
        loggedInUser,
        isRefreshing,
        isLoggedIn,
        refresh,
        getUserDomain,
        children
    }) => {

        useEffect(
            () => {
                if (!loginDetermined && !isRefreshing) {
                    refresh({ type: 'access' });
                }
                if (loginDetermined && isLoggedIn && !userDomainLoaded && loggedInUser) {

                    const userId = loggedInUser?._id;

                    if (userId) {
                        getUserDomain({ userId });
                    }
                }
            },
            [
                loginDetermined,
                isRefreshing,
                isLoggedIn,
                loggedInUser,
            ]
        );

        return <>{ children }</>;
    }
);
