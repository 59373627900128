import { eventChannel, END } from 'redux-saga';
import { call, fork, put, take, takeLatest, select } from 'redux-saga/effects';

import getSocketio from '@lib/socketio';
import { groupActions, createAPISaga } from '@lib/utils/redux';
import actions from './actions';
import selectors from './selectors';
import api from './api.js';
import { actions as storyActions } from '@state/stories';

const groupedActions = groupActions(actions);

const storyChannel = socket => eventChannel(emit => {

	const handler = (data) => {
		emit(data);
	};

	socket.on('NEW_STORY', handler);

	return () => {
		socket.off('NEW_STORY', handler);
	};
});

const startWorker = function* (action) {

	const socketio = getSocketio();

    const socket = socketio.getSocket('/poller');

    if (!socket) {
        throw new Error('Failed to retrieve socket');
    }

	const storyChan = yield call(storyChannel, socket);

	try {
		while (true) {

			const { payload: { story }} = yield take(storyChan);

			yield put(actions.storyFromSocket({ story }));
		}
	} finally  {
		storyChan.close();
	}
};

const start = function* () {
	yield takeLatest(actions.start, startWorker);
};

const storyFromSocket = function* () {

	while (true) {

		const action = yield take(actions.storyFromSocket);
		const storyIds = yield select(selectors.getIds);
		const toRemove = storyIds[ storyIds.length - 1 ];

		yield put(storyActions.clearStory({ storyId: toRemove }));
	}
};

const nextPage = function* () {

    const workerSaga = createAPISaga(api.nextPage, groupedActions.nextPage);

    yield takeLatest(groupedActions.nextPage.request, workerSaga);
};

const prevPage = function* () {

    const workerSaga = createAPISaga(api.prevPage, groupedActions.prevPage);

    yield takeLatest(groupedActions.prevPage.request, workerSaga);
};

export default function* () {

    const sagas = [
        start,
        storyFromSocket,
        nextPage,
        prevPage,
    ];

	for (let saga of sagas) {
		yield fork(saga);
	}
};
