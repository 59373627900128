import { createGQL } from '@lib/utils/graphql-api';
import config from '@nl/users/config.json';
import queries from '@nl/users/graphql/queries';
import userStd from '@nl/users/graphql/fragments/user-std.gqlf';

const gql = {
    queries,
    fragments: {
        userStd,
    },
};

const api = createGQL(config, gql);

export default api;
