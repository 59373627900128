import { connect } from 'react-redux';
import cn from 'classnames/bind';

import $users from '@state/users';
//import { actions as modalActs } from 'modal/redux';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	null,
	{
		//openModal: modalActs.open,
	}
)(
	({ user, _id, publicationId, title, subbed, openModal }) => (
		<li
			className={ s.root }
			title={ subbed ? 'Unsubscribe' : 'Subscribe' }
			onClick={
				() => subbed ?
					openModal({
						type: 'unsubscribeFeed',
						props: {
							feedId: _id,
							publicationId,
							userId: user._id,
							nsp: '/',
						},
					}) :
					openModal({
						type: 'subscribeFeed',
						props: {
							feedId: _id,
							publicationId,
							userId: user._id,
							nsp: '/',
						},
					})
			}
		>
			<span className={ s.title }>{ title }</span>
			{
				subbed ?
					<span
						className={ cn(sx('icon'), mx('icon-checkmark')) }
					/> :
					<span
						className={ cn(sx('icon'), mx('icon-rss')) }
					/>
			}
		</li>
	)
);
