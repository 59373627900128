import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import Select from 'react-select';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import $modal from '@state/modal';
import $users from '@state/users';
import $publications from '@state/publications';
import $socketio from '@state/socketio';

import Modal from '../templates/subscribe';

import s from './styles.scss';
import m from '../../styles.scss';

const sx = cn.bind(s);
const mx = cn.bind(m);

const unsubscribeFn = (
	unsubscribe,
	closeModal,
	user,
	publication,
	socketIds,
) => {

	unsubscribe({
		userId: user._id,
		socketId: socketIds[ '/poller' ],
		publicationId: publication._id,
	});

	closeModal();
};

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		socketIds: $socketio.selectors.getSocketIdsByNsp(state, props),
		publication: $publications.selectors.getPublication(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
	}),
	{
		closeModal: $modal.actions.close,
		unsubscribe: $users.actions.unsubscribeRequest,
	},
)(
	c(
		({ user, publication, socketIds, logoSrc, closeModal, unsubscribe }) => (
			<Modal
				header={
					() => <div className={ s.header }>Would you like to unsubscribe?</div>
				}
				body={
					() => (
						<div className={ s.pubRow }>
							<div className={ s.logoCont }>
								<img
									src={ logoSrc }
                                    width={ 30 }
								/>
							</div>
							<span className={ s.name }>{ publication?.name }</span>
						</div>
					)
				}
				footer={
					() => (
						<>
                            <button
								onClick={ closeModal }
								className={ mx('btn', 'btnCancel') }
							>
								No
							</button>
							<button
								onClick={
									() => unsubscribeFn(
										unsubscribe,
										closeModal,
										user,
										publication,
										socketIds
									)
								}
								className={ mx('btn', 'btnConfirm') }
							>
								Yes
							</button>
						</>
					)
				}
			/>
		)
	)
);
