import { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';
import { useFloating, flip, offset, shift, size } from '@floating-ui/react';
import FeedViewCtrl from '../feed-view-ctrl';
import FeedViewSelector from '@ui/components/feed-view-selector';
import FeedLayoutBtns from '../feed-layout-btns';
import { useClickAway } from '@lib/utils/react';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
	}),
	{
	}
)(
	c(
        ctx => {

            const clickAwayRef = useRef(null);
			const menuRef = useRef(null);

            const {
                refs,
                floatingStyles,
                strategy,
                update,
            } = useFloating({
                placement: 'bottom',
                middleware: [
                    offset(-1),
                    flip(),
                    size({
                        apply: (ctx) => {
                            const { availableWidth, reference, elements } = ctx;
                            Object.assign(elements.floating.style, {
                                minWidth: `${ elements.reference.offsetWidth }px`,
                            });
                        },
                    }),
                ],
            });

            useClickAway(clickAwayRef, e => {
                if (e.target.dataset.comp !== 'feedView') {
                    ctx.setMenuOpen(false);
                }
            });

            return (
                <div className={ s.root }>
                    <div className={ s.left }></div>
                    <div className={ s.center }>
                        <div className={ s.cont } ref={ clickAwayRef }>
                            <div className={ s.feedView } ref={ refs.setReference }>
                                <FeedViewCtrl
                                    clickHandler={ (e) => {
                                        ctx.setMenuOpen(!ctx.menuOpen)
                                    }}
                                />
                            </div>
                            {
                                ctx.menuOpen &&
                                <div
                                    ref={ refs.setFloating }
                                    style={ floatingStyles }
                                    className={ s.feedViewMenuCont }
                                >
                                    <div className={ s.feedViewMenu } ref={ clickAwayRef }>
                                        <FeedViewSelector />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={ s.right }>
                        <FeedLayoutBtns
                            layouts={ ctx.layouts }
                            toggle={ ctx.toggleLayout }
                        />
                    </div>
                </div>
            );
        }
    )
);
