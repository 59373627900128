import config from './config.json';
import actions from './actions';
import reducer from './reducers';
import selectors from './selectors';
import utils from './utils';

export { actions };
export { reducer };
export { selectors };
export { utils };

export default {
    actions,
    reducer,
    selectors,
    utils,
};
