import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import $app from '@state/app';
import $users from '@state/users';
import $feeds from '@state/feeds';
import $publications from '@state/publications';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		responsive: $app.selectors.getResponsive(state, props),
		feed: $feeds.selectors.makeGetFeedById()(state, props),
		publication: $publications.selectors.makeGetPublicationByFeedId()(state, props),
		logoSrc: $publications.selectors.getLogoSrc(
            state,
            { ...props, dim: '200', bg: 'dark' }
        ),
		subs: $users.selectors.getSubsByPubIds(state, props),
	}),
	{
		addBookmark: $users.actions.addBookmarkRequest,
	},
)(
	c(
		({
			user,
			subs,
			story,
			feed,
			publication,
			logoSrc,
			responsive,
			addBookmark,
			localeDateString,
			momentDateString,
		}) => (
			<div className={ s.root }>
				<span className={ s.col } title={ publication.name }>
					<img
						className={ s.storyImg }
						src={ logoSrc }
					/>
				</span>
				{
					responsive.md && (
						<span className={ s.col }>
							<span
								className={ s.feedTitle }
								title={ feed.title }
							>
								{ publication.name }
							</span>
						</span>
					)
				}
				<span className={ sx('col', 'link') }>
					<a
						href={ story.link }
						target='_blank'
					>
						{ story.title }
					</a>
					{
						responsive.md && (
							<span
								className={ cn(mx('icon-bookmark'), sx('bookmark')) }
								onClick={
									() => addBookmark({ userId: user._id, storyId: story._id })
								}
							/>
						)
					}
				</span>
				{
					responsive.md && (
						<span className={ sx('col', 'time') }>
							<span title={ localeDateString }>{ momentDateString }</span>
						</span>
					)
				}
			</div>
		),
	)
);
