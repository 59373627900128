import createGraphQLAPI, { createGQL } from '@lib/utils/graphql-api';
import config from '@nl/app/config.json';
import queries from '@nl/app/graphql/queries';
import userStd from '@nl/users/graphql/fragments/user-std.gqlf';

const gql = {
    queries,
    fragments: {},
};

const api = createGQL(config, gql);

export default api;
