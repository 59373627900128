import { createReducer } from '@lib/utils/redux';
import config from './config.json';
import actions from './actions';

const reducers = {
    socketConnected: (state, action) => {

        const socket = action.payload.socket;

        if (!(socket.nsp in state.socketsByNamespace)) {
            return;
        }

        state.socketIds.push(socket.id);
        state.socketsByNamespace[ socket.nsp ] = socket.id;
    },
    socketDisconnected: (state, action) => {

        const socket = action.payload.socket;
        const idx = state.socketIds.indexOf(socket.id);

        if (idx > -1) {
            state.socketIds.splice(idx, 1);
        }

        if (!(socket.nsp in state.socketsByNamespace)) {
            return;
        }

        state.socketsByNamespace[ socket.nsp ] = null;
    },
};

const reducer = createReducer(config, actions, reducers);

export default reducer;
