import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import $app from '@state/app';

export default connect(
	(state, props) => ({
	}),
	{
		setResponsive: $app.actions.setResponsive,
	}
)(
	({ setResponsive, children }) => {

		const respObj = {
			xs: useMediaQuery({ minWidth: 320 }),
			sm: useMediaQuery({ minWidth: 481 }),
			md: useMediaQuery({ minWidth: 769 }),
			lg: useMediaQuery({ minWidth: 1025 }),
			xl: useMediaQuery({ minWidth: 1201 }),
			portrait: useMediaQuery({ orientation: 'portrait' }),
			retina: useMediaQuery({ minResolution: '2dppx' }),
		};

		useEffect(
			() => {
				setResponsive(respObj);
			},
		);

        return <>{ children }</>;
	}
);
