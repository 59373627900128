import config from '@nl/search/config.json';
import actions from './actions';
import reducer from './reducers';
import saga from './sagas';
import selectors from './selectors';

export { config };
export { actions };
export { saga };
export { selectors };
export { reducer };

export default {
    config,
    actions,
    saga,
    selectors,
    reducer,
};
