import {
	clone, compose as c, curry, map, omit,
	propEq, reduce, reject, toPairs,
} from 'ramda';

export const pubOptData = pub => ({
	type: 'publication',
	value: pub._id,
	label: pub.name,
	obj: pub,
});

export const feedOptData = feed => ({
	type: 'feed',
	value: feed._id,
	label: feed.title,
	obj: feed,
});

export const getCurrentPool = curry(
	(feedIdsByPubId, groupItems) => {
		const byPubId = clone(feedIdsByPubId);
		return reduce(
		(pool, { id, type }) => {

			switch (type) {

				case 'publication':

					delete pool[ id ];
					return pool;

				case 'feed':

					for (let pubId in pool) {

						pool[ pubId ] = pool[ pubId ].filter(
							feedId => feedId !== id
						);

						if (!pool[ pubId ].length) {
							delete pool[ pubId ];
						}
					}
					return pool;
			}
		},
		byPubId,
	)(groupItems)
	}
);

export const optionToGroupItem = option => ({
	id: option.value,
	type: option.type,
});

export const currentValueToGroupItems = map(optionToGroupItem);

export const groupItemsAfterRemove = curry(
	(currentValue, option) => c(
		reject(propEq('id', option.value)),
		currentValueToGroupItems
	)(currentValue)
);

export const groupItemsAfterAdd = curry(
	(currentValue, option) => {

		const groupItems = currentValueToGroupItems(currentValue);

		switch (option.type) {

			case 'publication':

				const publication = option.obj;

				return [
					...groupItems.filter(o =>
						!publication.feeds.includes(o.id)
					),
					optionToGroupItem(option),
				];

			case 'feed':
				
				const feed = option.obj;

				return [
					...groupItems,
					optionToGroupItem(option),
				];

			default:
				return [];
		}
	}
);

export default {
    pubOptData,
    feedOptData,
    getCurrentPool,
    optionToGroupItem,
    currentValueToGroupItems,
    groupItemsAfterRemove,
    groupItemsAfterAdd,
};
