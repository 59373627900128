export const NODE_ENV = process.env.NODE_ENV;

export const APP_PROTOCOL = process.env.APP_PROTOCOL;
export const APP_HOST = process.env.APP_HOST;
export const APP_PORT = process.env.APP_PORT;

export const SOCKETIO_PROTOCOL = process.env.SOCKETIO_PROTOCOL;
export const SOCKETIO_HOST = process.env.SOCKETIO_HOST;
export const SOCKETIO_PORT = process.env.SOCKETIO_PORT;

export const NGINX_PORT = process.env.NGINX_PORT;

export const makeUrl = (protocol, host, port) => {

    let url = `${ protocol }://${ host }`;

    if (port !== '80') {
        url = `${ url }:${ port }`;
    }

    return url;
};

export const APP_SERVER_URL = makeUrl(
    APP_PROTOCOL,
    APP_HOST,
    NODE_ENV === 'production' ? NGINX_PORT : APP_PORT
);

export const SOCKETIO_URL = makeUrl(
    SOCKETIO_PROTOCOL,
    SOCKETIO_HOST,
    NODE_ENV === 'production' ? NGINX_PORT : SOCKETIO_PORT
);
