import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import Link from '@ui/components/link';

import { getHistory } from '@lib/history-manager';
import { useClickAway } from '@lib/utils/react';
import AppLogo from '@ui/components/app-logo';
import GroupsTab from '@ui/components/groups-tab';
import SubscriptionsTab from '@ui/components/subscriptions-tab';
import BookmarksTab from '@ui/components/bookmarks-tab';
import LogoutBtn from '@ui/components/logout-btn';

import $app from '@state/app';
import $auth from '@state/auth';
import $users from '@state/users';

import s from './styles.scss';
import z from '@ui/styles/base.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const zx = cn.bind(z);
const mx = cn.bind(m);

const navMenuD = [
    {
		id: 'feed',
		title: 'Feed',
		label: 'Feed',
		icon: 'icon-folder',
		visible: false,
		//component: <Groups userId={ user._id } />,
	},
];

const menuData = [
	{
		id: 'groups',
		title: 'Groups',
		label: 'Groups',
		icon: {
            type: 'icomoon',
            className: 'icon-folder',
        },
		visible: false,
		//component: <Groups userId={ user._id } />,
	},
	{
		id: 'subscriptions',
		title: 'Subscriptions',
		label: 'Subscriptions',
		icon: {
            type: 'font-awesome',
            className: 'fa-check',
        },
		visible: false,
		//component: <Subscriptions userId={ user._id } />,
	},
	{
		id: 'bookmarks',
		title: 'Bookmarks',
		label: 'Bookmarks',
		icon: {
            type: 'icomoon',
            className: 'icon-bookmarks',
        },
		visible: false,
		//component: <Bookmarks userId={ user._id } />,
	},
	/*{
		id: 'userSettings',
		title: 'User Settings',
		label: 'Settings',
		icon: 'icon-equalizer',
		component: <UserSettings
			userId={ user._id }
			username={ user.username }
			email={ user.email }
		/>
	},*/
];

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		responsive: $app.selectors.getResponsive(state, props),
		isMenuOpen: $app.selectors.isMenuOpen(state, props),
		isMenuPinned: $app.selectors.isMenuPinned(state, props),
        settings: $users.selectors.getSettings(state, props),
	}),
	{
		closeMenu: $app.actions.closeMenu,
		pinMenu: $app.actions.pinMenu,
		unpinMenu: $app.actions.unpinMenu,
        updateSettings: $users.actions.updateSettingsRequest,
        logout: $auth.actions.logoutRequest,
	}
)(
	c(
		({
            user,
            responsive,
            isMenuOpen,
            isMenuPinned,
            settings,
            closeMenu,
            pinMenu,
            unpinMenu,
            updateSettings,
            logout,
        }) => {

            if (!user) {
                return null;
            }

			let tab;

            const history = getHistory();
            const locationPath = history?.location?.pathname;

			const [ menuOpen, setMenuOpen ] = useState(false);
			const [ menuVisibility, setMenuVisibility ] = useState(menuData.reduce(
				(res, menuObj) => ({
					...res,
					[ menuObj.id ]: false,
				})
			));
            const [ visible, setVisible ] = useState('');

            const clickAwayRef = useRef(null);

			const pickMenu = id => {

				if (!id) {
					setMenuOpen(false);
                    setVisible('');

				} else {

					setMenuVisibility({
						...Object.keys(menuVisibility).reduce(
							(obj, key) => ({
								...obj,
								[ key ]: false,
							})
						),
						[ id ]: true,
					});

					setMenuOpen(true);
                    setVisible(id);
				}
			};

            useClickAway(
                clickAwayRef,
                e => {
                    if (isMenuOpen && !isMenuPinned) {
                        if (
                            e.target.className &&
                            typeof e.target.className === 'string' &&
                            !e.target.className.includes('src-ui-components-modal')
                        ) {
                            closeMenu();
                        }
                    }
                },
                [ isMenuOpen, isMenuPinned ]
            );

			return (
				<div
                    ref={ clickAwayRef }
					className={
						sx(
							'root',
							{
								menuOpen: isMenuOpen,
								menuClosed: !isMenuOpen,
							}
						)
					}
				>
					<div className={ sx('header') }>
                        <div className={ s.outer }></div>
						<div className={ sx('title') }>
							NewsLab
						</div>
						<div
							className={
                                sx(
                                    'close-cont',
                                    'outer',
                                    { pinned: isMenuPinned }
                                )
                            }
							onClick={ () => {
                                if (isMenuPinned) {
                                    unpinMenu();
                                    closeMenu();
                                    updateSettings({
                                        userId: user._id,
                                        settings: { ...settings, isMenuPinned: false },
                                    });
                                } else {
                                    pinMenu();
                                    updateSettings({
                                        userId: user._id,
                                        settings: { ...settings, isMenuPinned: true },
                                    });
                                }
                            }}
						>
                            <Fa icon='fa-solid fa-thumbtack' />
							{ false && <span className={ cn(zx('materialOutlined'), sx('close')) }>
                                arrow_left_alt
                            </span> }
						</div>
					</div>
                    <div className={ s.navMenu }>
                        <div className={ sx(
                            'sidebarNavBtn',
                            { active: /^\/@/.test(locationPath) }
                        )}>
                            <Link href={ `/@${ user.username }` } className={ s.link }>
                                <span className={ cn(mx('icon-rss')) }/>
                                <span className={ s.btnText }>
                                    Feed
                                </span>
                            </Link>
                        </div>
                        <div className={ sx(
                            'sidebarNavBtn',
                            { active: /^\/discover/.test(locationPath) }
                        )}>
                            <Link href='/discover' className={ s.link }>
                                <Fa icon='fa-solid fa-magnifying-glass' />
                                <span className={ s.btnText }>
                                    Discover
                                </span>
                            </Link>
                        </div>
                    </div>
					<div
						className={
							sx(
								'menuCont',
								{
									menuContentOpen: menuOpen,
									menuContentClosed: !menuOpen,
								}
							)
						}
					>
						<div className={ s.menuCol }>
							<div className={ s.listMenuCont }>
								{
									menuData.map(
										({ id, title, label, icon, component }) => (
											<div
												key={ id }
												className={ s.sidebarBtn }
												title={ title }
												onClick={
													() => pickMenu(id)
												}
											>
												{ icon.type === 'icomoon' &&
                                                    <span className={
                                                        cn(mx(icon.className))
                                                    }/>
                                                }
												{ icon.type === 'font-awesome' &&
													<Fa icon={ `fa-solid ${ icon.className }`} />
                                                }
												<span className={ s.btnText }>
													{ title }
												</span>
												<span className={ s.chevron }>
													<Fa icon='fa-solid fa-chevron-right' />
												</span>
												<div className={
													sx('arrow', { active: id === tab })
												} />
											</div>
										)
									)
								}
							</div>
						</div>
						<div className={ s.menuContent }>
							<div className={ s.menuBack } onClick={ () => pickMenu() }>
                                <div className={ s.backBtn }>
                                    <span className={ s.chevron }>
                                        <Fa icon='fa-solid fa-chevron-left' />
                                    </span>
                                </div>
								<span className={ s.backText }>
                                    {
                                        menuData.find(m => m.id === visible)?.title
                                    }
                                </span>
                                <div className={ s.outer }>
                                </div>
							</div>
							<div className={ s.submenuCont }>
								{ menuVisibility.groups && <GroupsTab /> }
								{ menuVisibility.subscriptions && <SubscriptionsTab /> }
								{ menuVisibility.bookmarks && <BookmarksTab /> }
							</div>
						</div>
					</div>
                    {
                        (!user || !user.demoUser) &&
                        <div
                            className={ s.lowerMenu }
                            title='Logout'
                            onClick={ logout }
                        >
                            <Fa icon='fa-solid fa-arrow-right-from-bracket' />
                            <span className={ s.btnText }>Sign out</span>
                        </div>
                    }
				</div>
			);
		},
	)
);
