import { createSelector } from '@reduxjs/toolkit';

const identity = state => state.search;

const getSearchResults = ({ publications, search }) => search.reduce(
    (all, id) => id in publications.byId ?
        [ ...all, publications.byId[ id ] ] :
        all,
    []
);

export default {
    getSearchResults,
};
