import { createReducer } from '@lib/utils/redux';
import config from '@nl/search/config.json';
import actions from './actions';

const reducerImports = {};

const reducers = {
    searchSuccess: (state, action) => {
        const result = action.payload.result;
        return result.publications.map(p => p._id);
    },
};
const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
