import { connect } from 'react-redux';
import cn from 'classnames/bind';

import $app from '@state/app';
import $users from '@state/users';

import Publication from './publication';

import s from './styles.scss';

const sx = cn.bind(s);

export default connect(
	(state, props) => ({
		subscriptions: $users.selectors.getSubscriptions(state, props),
		responsive: $app.selectors.getResponsive(state, props),
	}),
	{},
)(
	({ userId, subscriptions, responsive }) => (
		<div className={ sx('root', { mobile: !responsive.md }) }>
			{
				!subscriptions.length &&
					<div className={ s.emptySubs }>
						No subscriptions
					</div>
			}
			{
				!!subscriptions.length && subscriptions.map(sub => (
					<Publication
						key={ sub.publicationId }
						subscription={ sub }
						publicationId={ sub.publicationId }
						className={ s.sub }
					/>
				))
			}
		</div>
	)
);
