import { eventChannel, END } from 'redux-saga';
import { call, fork, put, take, takeLatest, select } from 'redux-saga/effects';

import { groupActions, createAPISaga } from '@lib/utils/redux';
import actions from './actions';
import api from './api.js';

const groupedActions = groupActions(actions);

const getUserDomain = function* () {

    const workerSaga = createAPISaga(api.getUserDomain, groupedActions.getUserDomain);

    yield takeLatest(groupedActions.getUserDomain.request, workerSaga);
};

export default function* () {

	for (let saga of [ getUserDomain ]) {
		yield fork(saga);
	}
};
