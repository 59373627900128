import config from './config.json';
import actions from './actions';
import reducer from './reducers';
import saga from './sagas';
import selectors from './selectors';

export { actions };
export { saga };
export { reducer };
export { selectors };

export default {
    actions,
    saga,
    reducer,
    selectors,
};
