import { connect } from 'react-redux';
import Link from '@ui/components/link';
import LinkBtn from '@ui/components/header/link-btn';
import ls from '@ui/components/header/link-btn/styles.scss';
import $users from '@state/users';
import s from './styles.scss';

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
    }),
    {
    }
)(
    ({ user }) => {

        if (!user) {
            return null;
        }

        return (
            <div className={ s.root }>
                <LinkBtn>
                    <Link href={ `/@${ user.username }` } className={ ls.link }>
                        Home
                    </Link>
                </LinkBtn>
                <LinkBtn>
                    <Link href='/discover' className={ ls.link }>
                        Discover
                    </Link>
                </LinkBtn>
            </div>
        );
    }
);
