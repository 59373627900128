import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';

import $users from '@state/users';
import $publications from '@state/publications';
import $modal from '@state/modal';

import Image from '@ui/components/image';

import z from '@ui/styles/base.scss';
import s from './styles.scss';

const sx = cn.bind(s);
const zx = cn.bind(z);

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		logoSrc: $publications.selectors.getLogoSrc(
            state,
            {
                ...props,
                dim: '200',
                bg: 'dark'
            }
        ),
	}),
	{
		openModal: $modal.actions.open,
	},
)(
	c(
		({ user, feedId, bookmark, logoSrc, openModal, responsive }) => (
			<div className={ s.root }>
				<div className={ s.logoCont }>
					<Image
						className={ s.logo }
						src={ logoSrc }
						width={ 25 }
						defaultSrc='/imgs/newspaper.png'
					/>
				</div>
				<div className={ s.title } title={ bookmark.title }>
					<a className={ s.link } href={ bookmark.link } target='_blank'>
						{ bookmark.title }
					</a>
				</div>
				<div
					className={ s.unsub }
					onClick={
						() => openModal({
							type: 'removeBookmark',
							props: {
								userId: user._id,
								story: bookmark,
								feedId,
							},
						})
					}
				>
                    <span className={ cn(zx('materialOutlined'), sx('close')) }>
                        close_small
                    </span>
				</div>
			</div>
		)
	)
);
