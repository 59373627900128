import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

const ident = state => state.app;

const propSelector = p => createSelector(ident, prop(p));

export const userDomainLoaded = propSelector('userDomainLoaded');

export const isLoading = propSelector('loading');

export const isMenuPinned = propSelector('menuPinned');

export const isMenuOpen = propSelector('menuOpen');

export const getResponsive = propSelector('responsive');

export const getMobile = propSelector('mobile');

export const getMobileMenuStack = createSelector(
    getMobile,
    mobile => mobile.menu.stack
);

export const getMobileMenuLastPushed = createSelector(
    getMobileMenuStack,
    stack => stack[ stack.length - 1 ]
);

export const getFeedLayouts = propSelector('feedLayouts');

export default {
    userDomainLoaded,
    isLoading,
    isMenuPinned,
    isMenuOpen,
    getResponsive,
    getMobile,
    getMobileMenuStack,
    getMobileMenuLastPushed,
    getFeedLayouts,
};
