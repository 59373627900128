import createGraphQLAPI from '@lib/utils/graphql-api';
import search from '@nl/search/graphql/queries/search.gqlq';
import popular from '@nl/search/graphql/queries/popular.gqlq';
import config from '@nl/search/config.json';

const gql = {
    queries: {
        search,
        popular,
    },
    fragments: {},
};

const api = createGraphQLAPI(config, gql);

export default api;
