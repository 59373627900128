import axios from 'axios';
import {
	compose as c, construct, defaultTo, forEach,
	head, ifElse, includes, is, mergeLeft, mergeRight,
	objOf, pick, prop,
} from 'ramda';

import { APP_SERVER_URL } from '@src/env';

const loadPayload = (payloadSpec, data, method) => {

	let payload;

	if (data instanceof FormData) {

		payload = new FormData();

		forEach(
			([ key, val ]) => {
				if (includes(key, payloadSpec)) {
					payload.append(key);
				}
			},
			[ ...data.entries() ]
		);

	} else {

		payload = pick(payloadSpec, data);
	}

	return payload;
};

export const defaultsFromConf = conf => {

    const defaults = {
        baseUrl: conf.baseUrl || APP_SERVER_URL,
        path: conf.endpoint.path,
        method: conf.endpoint.method,
        ...conf,
        payloadKeys: conf.payloadKeys[ 0 ],
    };

    return defaults;
};

export default defaults => {

    let { baseUrl, path, method, payloadKeys } = defaults;
    let url;

    if (baseUrl) {
        if (path) {
            url = new URL(path, baseUrl);
        } else {
            url = new URL(baseUrl);
        }
    }

    return async (data, opts = {}) => {

        if (opts.baseUrl) {
            if (opts.path) {
                url = new URL(opts.path, opts.baseUrl);
            } else {
                url = new URL(opts.baseUrl);
            }
        }

        if (opts.method) {
            method = opts.method;
        }

        const payload = loadPayload(payloadKeys, data, method);
        const baseOpts = { method: method || 'GET' };

        let axiosOpts;

        if (method === 'GET') {
            url.search = new URLSearchParams(payload);
            axiosOpts = mergeRight(baseOpts, opts);

        } else {
            axiosOpts = c(
                mergeRight(baseOpts),
                mergeRight(opts),
            )({ data: payload });
        }

        const res = await axios({ url, ...axiosOpts });

        return res.data;
    };
};
