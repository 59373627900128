import { fork, call, cancel, cancelled, take, put, takeLatest } from 'redux-saga/effects'
import { groupActions, createAPISaga } from '@lib/utils/redux';
import { actions } from './';
import api from './api';

const search = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.search, acts.search);

    yield takeLatest(acts.search.request, workerSaga);
};

const popular = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.popular, acts.popular);

    yield takeLatest(acts.popular.request, workerSaga);
};

export default function* () {

    const sagas = [
        search,
        popular,
    ];

	for (let saga of sagas) {
		yield fork(saga);
	}
};
