import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { v4 as uuid } from 'uuid';

import $publications from '@state/publications';
import Image from '@ui/components/image';
import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

export default connect(
	(state, props) => {
		const { data } = props;
		const { obj, type } = data;

		if (obj) {
			const publicationId = type === 'publication' ? obj._id : obj.publicationId;

			return {
				type,
				logoSrc: $publications.getLogoSrc(
                    state,
                    {
                        ...props,
                        publicationId,
                        dim: '200',
                        bg: 'dark'
                    }
                ),
			};
		} else {
			return {};
		}
	},
	{}
)(
	props => (
		<div key={ uuid() } className={ s.multiValueLabel }>
			{ props.type === 'group' && (
				<span className={ s.folderIconCont }>
					<span className={ m[ 'icon-folder' ] }/>
				</span>
			)}
			{ props.type !== 'group' && 
			<span className={ s.multiValueLabelImgCont }>
				<img
					src={ props.logoSrc }
					width={ 20 }
					styles={{
						borderRadius: '2px',
					}}
				/>
			</span>
			}
			<span className={ s.multiValueLabelTxt }>
				<components.MultiValueLabel { ...props }/>
			</span>
		</div>
	)
);
