import { connect } from 'react-redux';
import { components } from 'react-select';
import Image from '@ui/components/image';
import $publications from '@state/publications';

import s from './styles.scss';

export default connect(
	(state, props) => ({
		logoSrc: $publications.selectors.getLogoSrc(state, {
			...props,
			publicationId: props.data.obj._id,
			dim: '200',
			bg: 'dark'
		}),
	}),
	null,
)(
	props => props.data.type === 'publication' ? (
		<components.Option { ...props }>
			<Image
				src={ props.logoSrc }
				width={ 20 }
				height={ 20 }
				defaultSrc='/imgs/newspaper.png'
				styles={{
					margin: 'auto 0',
					borderRadius: '2px',
				}}
			/>
			<div className={ s.optLabel }>{ props.label }</div>
		</components.Option>
	) :
	<components.Option { ...props }>
		<div className={ s.optFeed }>{ props.label }</div>
	</components.Option>
);
