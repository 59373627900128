import { call, put } from 'redux-saga/effects'
import {
    createAction,
    createReducer as _createReducer,
    combineReducers,
    nanoid,
    current,
} from '@reduxjs/toolkit';

import {
    always, applySpec, assoc, compose as c, concat, curry, forEach, isNil, isEmpty,
    join, keys, fromPairs, map, mergeLeft, mergeRight, not, omit, or, pick,
    toPairs, reduce, reverse, split,
} from 'ramda';

import { capitalize, camelCase, snakeCase, toUpper, toLower } from 'lodash';

const asyncTypes = [
    'request',
    'success',
    'failure'
];

export const snakeUpper = c(toUpper, snakeCase);

export const groupActions = c(
    reduce(
        (acc, [ handle, action ]) => {

            let [ suffix, ...name ] = reverse(split('_', snakeCase(handle)));

            if (!asyncTypes.includes(suffix.toLowerCase())) {
                return acc;
            }

            name = c(
                camelCase,
                join('_'),
                reverse
            )(name);

            return {
                ...acc,
                [ name ]: {
                    ...acc[ name ],
                    [ suffix ]: action,
                },
            };
        },
        {}
    ),
    toPairs,
);

export const makeSyncActionType = curry((modId, actionName) => {
    return join('/', [ camelCase(modId), camelCase(actionName) ]);
});

export const makeAsyncActionTypes = curry(
    (modId, actionName) => fromPairs(asyncTypes.map(suffix => {

        const handle = concat(
            camelCase(actionName),
            capitalize(suffix),
        );

        return [
            handle,
            join(
                '/',
                [
                    camelCase(modId),
                    handle,
                ]
            )
        ];
    })
));

export const createActions = config => {

    const modId = config.id;
    const actions = config.actions || {};
    const syncActionObjs = actions.sync ?? {};
    const asyncActionObjs = actions.async ?? {};

    const syncActions = createSyncActions(modId, syncActionObjs);
    const asyncActions = createAsyncActions(modId, asyncActionObjs);

    return {
        ...syncActions,
        ...asyncActions,
    };
};

export const createSyncActions = (modId, syncActionObjs) => c(
    reduce(
        (acc, name) => assoc(
            name,
            createAction(
                makeSyncActionType(modId, name),
                (payload, meta) => {

                    try {

                        return applySpec({
                            payload: pick(syncActionObjs[ name ].payloadKeys),
                            meta: always({
                                id: nanoid(),
                                createdAt: new Date().toISOString(),
                                ...meta,
                            }),
                        })(payload);

                    } catch (e) {
                        console.log(`Redux Synchronous Action Error in "${ modId }: ${ name }": ` + e);
                    }
                },
            ),
            acc
        ),
        {},
    ),
    keys,
)(syncActionObjs);

export const createAsyncActions = (modId, asyncActionObjs) => c(
    reduce(
        (acc, name) => c(
            mergeLeft(acc),
            reduce(
                (all, [ handle, type ]) => assoc(
                    handle,
                    createAction(
                        type,
                        (payload, meta) => {
                            try {
                                const asyncType = split('_', snakeCase(handle)).pop();

                                let payloadFn = payload => { payload };

                                if (asyncType === 'request') {
                                    payloadFn = pick(asyncActionObjs[ name ].payloadKeys[ 0 ]);
                                } else if (asyncType === 'success') {
                                    payloadFn = pick(asyncActionObjs[ name ].payloadKeys[ 1 ]);
                                }

                                return applySpec({
                                    payload: payloadFn,
                                    meta: always({
                                        id: nanoid(),
                                        createdAt: new Date().toISOString(),
                                        ...meta,
                                    }),
                                })(payload);

                            } catch (e) {
                                console.log('Error', name, handle, type, e);
                            }
                        }
                    ),
                    all
                ),
                {},
            ),
            toPairs,
            makeAsyncActionTypes(modId)
        )(name),
        {},
    ),
    keys,
)(asyncActionObjs);

const __createReducer = (initialState, actions, functions, imports) => _createReducer(
    initialState,
    builder => {

        c(
            forEach(([ handle, fn ]) => {

                let action;

                try {
                    const parts = handle.split('.');

                    if (parts.length > 1) {

                        const [ modId, actionName ] = parts;

                        if (!(modId in imports)) {
                            return console.log(`Missing import "${ modId }"`);
                        }
                        const importedMod = imports[ modId ];

                        if (!(actionName in importedMod)) {
                            return console.log(
                                `Missing action "${ actionName }" from ${ modId }`);
                        }

                        action = importedMod[ actionName ];
                        builder = builder.addCase(action, fn);

                    } else {
                        action = actions[ handle ];
                        builder = builder.addCase(action, fn);
                    }
                } catch (e) {
                    console.log('imports', imports, 'actions', actions);
                    throw new Error(`Adding case "${ handle }", action "${ action }", ` + e);
                }
            }),
            toPairs,
        )(functions);
    },
);

export const createReducer = (config, actions, functions, imports = {}) => {

    let id;

    try {

        id = config.id;

        const reducerConfig = config.plugins.redux.reducer;
        const initialState = reducerConfig.initialState;
        const reducerKeys = reducerConfig.keys;

        if (reducerKeys && reducerKeys.length) {
            return c(
                combineReducers,
                reduce(
                    (acc, [ reducerKey, fns ]) => {
                        return assoc(
                        reducerKey,
                        __createReducer(
                            initialState[ reducerKey ],
                            actions,
                            functions[ reducerKey ],
                            imports,
                        ),
                        acc
                    )},
                    {}
                ),
                toPairs,
            )(functions);
        }

        return __createReducer(initialState, actions, functions, imports);

    } catch (e) {
        if (id) {
            throw new Error(`In "${ id }", ` + e);
        }
        throw e;
    }
};

export const createAPISaga = curry(
	(api, actions, opts = {}) => {

		return function* (action) {

			let response;
			
			try {
				response = yield call(api, action.payload);

				if (!response.success) {
					yield put(actions.failure(response.data));

				} else {

					let metaRequest = action.payload;

					const { omitMetaReqProps } = opts;

					if (not(or(isNil(omitMetaReqProps), isEmpty(omitMetaReqProps)))) {
						metaRequest = omit(omitMetaReqProps, metaRequest);
					}

					yield put(actions.success(
						response.data,
						{ req: metaRequest }
					));

					return response.data;
				}
			} catch (e) {
				yield put(actions.failure({ message: e.toString() }));
			}
		};
	}
);
