interface Props {
    components: Array<React.JSXElementConstructor<React.PropsWithChildren<unknown>>>
    children: React.ReactNode
}

export default function Compose(props: Props) {

    const { components = [], children } = props

    return (
        <>
            {
                components.reduceRight(
                    (acc, Comp) => <Comp>{acc}</Comp>,
                    children
                )
            }
        </>
    );
}
