import cn from 'classnames/bind';
import s from './styles.scss';

const sx = cn.bind(s);

export default ({ list }) => (
    <ul className={ sx('list') }>
        {
            list.map((comp, i) => (
                <li key={ i } className={ sx('item') }>
                    { comp }
                </li>
            ))
        }
    </ul>
);
