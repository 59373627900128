import * as r from 'ramda';
import { createReducer } from '@lib/utils/redux';
import config from '@nl/app/config.json';

import actions from './actions';

const reducers = {
    getUserDomainSuccess: (state, action) => {
        const userSettings = action?.payload?.userDomainResult?.user?.settings;
        const isMenuPinned = !!userSettings.isMenuPinned;
        state.menuPinned = isMenuPinned;
        state.menuOpen = isMenuPinned;
        state.userDomainLoaded = true;
    },
    pinMenu: (state, action) => {
        state.menuPinned = true;
    },
    unpinMenu: (state, action) => {
        state.menuPinned = false;
    },
    openMenu: (state, action) => {
        state.menuOpen = true;
    },
    closeMenu: (state, action) => {
        state.menuOpen = false;
    },
    pushMenu: (state, action) => {
        const id = action.payload?.id;
        state.mobile.menu.stack.push(id);
    },
    popMenu: (state, action) => {
        state.mobile.menu.stack.pop();
    },
    setLoadingOn: (state, action) => {
        state.loading = true;
    },
    setLoadingOff: (state, action) => {
        state.loading = false;
    },
    setResponsive: (state, action) => {
        const { payload } = action;
        state.responsive = {
            xs: payload.xs,
            sm: payload.sm,
            md: payload.md,
            lg: payload.lg,
            xl: payload.xl,
            portrait: payload.portrait,
            retina: payload.retina,
        };
    },
    setVisible: (state, action) => {

        const comp = action.payload?.comp;

        if (comp !== 'menu') {
            state.mobile.menu.stack = [];
        }

        state.mobile.prevVisible = state.mobile.visible;
        state.mobile.visible = comp;
    },
    toggleLoading: (state, action) => {
        state.loading = !state.loading;
    },
    toggleFeedLayout: (state, action) => {

        const id = action.payload?.id;

        state.feedLayouts = Object.fromEntries(
            Object.entries(state.feedLayouts).reduce(
                (arr, [ layoutId, isOpen ]) => [
                    ...arr,
                    [ layoutId, layoutId === id ],
                ],
                []
            )
        );
    },
};

const reducer = createReducer(config, actions, reducers);

export default reducer;
