import { fork, takeLatest } from 'redux-saga/effects'
import { groupActions, createAPISaga } from '@lib/utils/redux';
import actions from './actions';
import api from './api';

const createTag = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.createTag, acts.createTag);

    yield takeLatest(acts.createTag.request, workerSaga);
};

const deleteTag = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.deleteTag, acts.deleteTag);

    yield takeLatest(acts.deleteTag.request, workerSaga);
};

const getTags = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getTags, acts.getTags);

    yield takeLatest(acts.getTags.request, workerSaga);
};

export default function* () {

    const sagas = [
        createTag,
        deleteTag,
        getTags,
    ];

	for (let saga of sagas) {
		yield fork(saga);
	}
};
