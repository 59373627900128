import config from './config.json';
import actions from './actions';
import reducer from './reducers';
import selectors from './selectors';

export { config };
export { actions };
export { selectors };
export { reducer };

export default {
    config,
    actions,
    selectors,
    reducer,
};
