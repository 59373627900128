import FEED_STD_FRAG from '@nl/feeds/graphql/fragments/feed-std.gqlf';
import FEED_PLUS_FRAG from '@nl/feeds/graphql/fragments/feed-plus.gqlf';

const FEED_STD = '...feedStd';
const FEED_PLUS = `
    total
    feeds {
        ...feedPlus
    }
    distinct {
        publication {
            _id
            name
        }
    }
`;

const FEED_MIN = `
    _id
    publicationId
`;

const createFeed = (fields = FEED_STD, fragments = FEED_STD_FRAG) => `
mutation CreateFeed($input: FeedInput!) {
    feed: createFeed(input: $input) {
        ${ fields }
    }
}
${ fragments }
`;

const deleteFeed = (fields = FEED_MIN, fragments = '') => `
mutation DeleteFeed($feedId: ID!, $publicationId: ID!) {
    feed: deleteFeed(feedId: $feedId, publicationId: $publicationId) {
        ${ fields }
    }
}
${ fragments }
`;

const getFeed = (fields = FEED_STD, fragments = FEED_STD_FRAG) => `
query GetFeed($feedId: ID!) {
    feed: getFeed(feedId: $feedId) {
        ${ fields }
    }
}
${ fragments }
`;

const getFeedsByIds = (fields = FEED_STD, fragments = FEED_STD_FRAG) => `
query GetFeedsByIds($ids: [ ID ]!) {
    feeds: getFeedsByIds(ids: $ids) {
        ${ fields }
    }
}
${ fragments }
`;

const getFeeds = (fields = FEED_PLUS, fragments = FEED_PLUS_FRAG) => `
query GetFeeds($publicationId: ID, $pageOpts: FeedPageOptsInput, $withInput: WithInput) {
    results: getFeeds(publicationId: $publicationId, pageOpts: $pageOpts, withInput: $withInput) {
        ${ fields }
    }
}
${ fragments }
`;

const getPublicationFeeds = (fields = FEED_STD, fragments = FEED_STD_FRAG) => `
query GetPublicationFeeds($publicationId: ID) {
    feeds: getPublicationFeeds(publicationId: $publicationId) {
        ${ fields }
    }
}
${ fragments }
`;

const updateFeed = (fields = FEED_STD, fragments = FEED_STD_FRAG) => `
mutation UpdateFeed($feedId: ID!, $updates: UpdateFeedInput!) {
    feed: updateFeed(feedId: $feedId, updates: $updates) {
        ${ fields }
    }
}
${ fragments }
`;

export default {
    createFeed,
    deleteFeed,
    getFeed,
    getFeedsByIds,
    getFeeds,
    getPublicationFeeds,
    updateFeed,
};
