import { createSelector } from '@reduxjs/toolkit';
import { compose as c, identity, nthArg, prop } from 'ramda';
import { basicOpts } from '@lib/utils/react';

const self = state => state.tags;

export const getTagIds = createSelector(
    self,
    prop('allIds')
);

export const getTagsMap = createSelector(
    self,
    prop('byId')
);

export const getTagId = c(prop('tagId'), nthArg(1));

export const getTag = createSelector(
    [ getTagsMap, getTagId ],
    (tags, tagId) => tags[ tagId ]
);

export const getSelectTagIds = (_, { tagIds }) => tagIds;

export const getTags = createSelector(
    [ getTagsMap, getTagIds ],
    (tags, ids) => {
        return ids.map(id => tags[ id ])
    }
);

export const makeGetTags = () => createSelector(getTags, identity);

export const getTagOpts = createSelector(
    [ getSelectTagIds, getTagIds, getTagsMap ],
    (selectTagIds, allIds, allTags) => basicOpts(
        selectTagIds || allIds,
        allTags,
        'name'
    )
);

export const makeGetTagOpts = () => createSelector(getTagOpts, identity);

export default {
    getTagIds,
    getTagsMap,
    getTagId,
    getTag,
    getSelectTagIds,
    getTags,
    makeGetTags,
    getTagOpts,
    makeGetTagOpts,
};
