import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';

import $modal from '@state/modal';

import z from '@ui/styles/base.scss';
import m from '../../../styles.scss';

const zx = cn.bind(z);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
		modalOpen: $modal.selectors.isOpen(state, props),
	}),
	{
		openModal: $modal.actions.open,
		closeModal: $modal.actions.close,
	},
)(
    c(
        ({
			modalOpen,
			closeModal,
			header,
			body,
			footer
		}) => (
            <ReactModal
				isOpen={ modalOpen }
				onRequestClose={ closeModal }
				shouldCloseOnOverlayClick={ true }
				shouldCloseOnEsc={ true }
				className={ m.mainModal }
				overlayClassName={ m.mainModalOverlay }
				closeTimeoutMS={ 2000 }
			>
				<div className={ m.root }>
					{ header &&
                        <div className={ m.header }>
                            { header() }
                        </div>
                    }
					{ body &&
                        <div className={ m.mainContent }>
                            { body() }
                        </div>
                    }
					{ footer && 
                        <div className={ m.footer }>
                            { footer() }
                        </div>
                    }
				</div>
                <span
                    className={ cn(zx('materialOutlined'), mx('close')) }
                    onClick={ () => closeModal() }
                >
                    close
                </span>
			</ReactModal>
        )
    )
);
