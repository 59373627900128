import * as r from 'ramda';
import { createReducer } from '@lib/utils/redux';
import config from '@nl/feed/config.json';
import actions from './actions';
import { actions as app } from '@state/app';
import { actions as users } from '@state/users';
import { actions as groups } from '@state/groups';
import { actions as stories } from '@state/stories';

const reducerImports = {
    app,
    users,
    groups,
    stories,
};

const reducers = {
    nextPageSuccess: (state, action, meta) => {

        const stories = action.payload?.stories;
		const storyIds = r.map(r.prop('_id'), stories);

		state.items = storyIds;
		state.page = meta.req.currentPage + 1;
	},
    prevPageSuccess: (state, action, meta) => {

        const stories = action.payload?.stories;
		const storyIds = r.map(r.prop('_id'), stories);

		state.items = storyIds;
		state.page = meta.req.currentPage - 1;
	},
    storyFromSocket: (state, action) => {

        const story = action.payload?.story || {};

        if (state.page === 1) {
            state.items.unshift(story._id);
        }
    },
	'app.getUserDomainSuccess': (state, action) => {

		state.items = action.payload?.userDomainResult?.feedIds;
	},
	'users.setCurrentViewSuccess': (state, action) => {

        const stories =  action.payload?.setViewResult?.stories;
		const storyIds = r.map(r.prop('_id'), stories);

		state.items = storyIds;
	},
	'users.subscribeSuccess': (state, action) => {

        const stories = action.payload?.subscribeResult?.stories;

		if (Array.isArray(stories)) {
			const storyIds = r.map(r.prop('_id'), stories);
			state.items = storyIds;
		}
	},
	'users.unsubscribeSuccess': (state, action) => {

        const stories = action.payload?.unsubscribeResult?.stories;

		if (Array.isArray(stories)) {
			const storyIds = r.map(r.prop('_id'), stories);
			state.items = storyIds;
		}
	},
    'users.getPrevFeedPageSuccess': (state, action) => {

        const stories = action.payload?.stories;

        if (Array.isArray(stories)) {
            const storyIds = r.map(r.prop('_id'), stories);

            state.items = [ ...state.items, ...storyIds ];
        }
    },
	'groups.updateGroupSuccess': (state, action, meta) => {

        const stories = action.payload?.groupUpdateResult?.stories;

		if (Array.isArray(stories)) {
			const storyIds = r.map(r.prop('_id'), stories);
			state.items = storyIds;
		}
	},
	'stories.findUserStoriesSuccess': (state, action) => {

        const stories =  action.payload?.stories;
		const storyIds = r.map(r.prop('_id'), stories);

		state.items = storyIds;
	},
	'stories.clearStory': (state, action) => {

        const storyId = action.payload?.storyId;

		state.items.splice(state.items.indexOf(storyId), 1);
	}
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
