import createGraphQLAPI from '@lib/utils/graphql-api';
import config from '@nl/tags/config.json';
import createTag from '@nl/tags/graphql/queries/create-tag.gqlq';
import deleteTag from '@nl/tags/graphql/queries/delete-tag.gqlq';
import getTags from '@nl/tags/graphql/queries/get-tags.gqlq';

const gql = {
    queries: {
        createTag,
        deleteTag,
        getTags,
    },
    fragments: {},
};

const api = createGraphQLAPI(config, gql);

export default api;
