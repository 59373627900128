import { useEffect } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import cn from 'classnames/bind';

import $app from '@state/app';
import $search from '@state/search';

import Publication from '../publication';

import s from './styles.scss';

const sx = cn.bind(s);

export default connect(
	(state, props) => ({
		searchResults: $search.selectors.getSearchResults(state, props),
		responsive: $app.selectors.getResponsive(state, props),
	}),
	{
		popular: $search.actions.popularRequest,
	},
)(
	({ userId, searchResults, popular, responsive }) => {

		return (
			<div className={ sx('root', { mobile: !responsive.md }) }>
				{ responsive.md && !searchResults.length && 
					<div className={ s.emptySearchResults }>
						Nothing found
					</div>
				}
				{ !!searchResults.length &&
					<ul className={ sx('searchResultList', { mobile: !responsive.md }) }>
					{
						searchResults.map(publication => (
							<Publication
								key={ publication._id }
								publicationId={ publication._id }
								userId={ userId }
							/>
						))
					}
					</ul>
				}
			</div>
		);
	}
);
