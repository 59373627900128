import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { bindThis } from '@lib/utils/react';
import cn from 'classnames/bind';

import $app from '@state/app';
import $search from '@state/search';

import s from './styles.scss';

const sx = cn.bind(s);

class Search extends React.Component {

	constructor() {

		super();

		this.state = {
			query: '',
		};

		bindThis(this, [ 'onChange', 'onKeyPress', 'search' ]);

		this.debouncedSearch = debounce(this.search, 700);
	}

	onChange(e) {

		this.setState({ query: e.target.value });
		this.debouncedSearch();
	}

	onKeyPress(e) {

		if (e.key == 'Enter') {
			this.search();
		}
		return false;
	}

	search() {

		const { search, callback } = this.props;
		const { query } = this.state;

		search({ query });

		if (callback) {
			callback();
		}
	}

	render() {

		const { inputRef, inputRootClass, inputClass, responsive } = this.props;
		const { query } = this.state;

		return (
			<div className={ inputRootClass || s.root }>
				<input
					type='text'
					ref={ inputRef }
					className={ inputClass || s.searchInput }
                    placeholder={ '\uf002' }
					onChange={ this.onChange }
					onKeyPress={ this.onKeyPress }
					value={ query }
                    autoFocus
				/>
				{
					query &&
						<div
							className={ sx('close', { mobile: !responsive.md }) }
							onClick={
								() => {
									this.setState({
										query: '',
									});
									inputRef.current.focus();
								}
							}
						>
							×
						</div>
				}
			</div>
		);
	}
}

export default connect(
	(state, props) => ({
		responsive: $app.selectors.getResponsive(state, props),
	}),
	{
		search: $search.actions.searchRequest,
	}
)(Search);
