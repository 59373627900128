import HistoryManager from '@lib/utils/history';

let historyManager;

export const getHistory = () => {
    if (historyManager) {
        return historyManager.history;
    }
};

export default history => {

    if (historyManager && !history) {
        return historyManager;
    }

    if (!history) {
        throw new Error('History missing');
    }

    historyManager = new HistoryManager(history);

    return historyManager;
};
