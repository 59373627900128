import LoginPage from './pages/login';
import UserPage from './pages/user';
import DiscoverPage from './pages/discover';

export default [
    {
        path: '/',
        action: () => {
            return <div>my app</div>;
        },
    },
    {
        path: '/login',
        action: () => {
            return <LoginPage />;
        },
    },
    {
		path: '/@:username',
		action: async ({ params, next, store }) => {

            return <UserPage />;
		},
	},
    {
        path: '/discover',
        action: () => {
            return <DiscoverPage />;
        },
    },
];
