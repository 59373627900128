import createGraphQLAPI from '@lib/utils/graphql-api';
import config from '@nl/search/config.json';
import search from '@nl/search/graphql/queries/search.gqlq';
import popular from '@nl/search/graphql/queries/popular.gqlq';

import feedStd from '@nl/feeds/graphql/fragments/feed-std.gqlf';
import feedPlus from '@nl/feeds/graphql/fragments/feed-plus.gqlf';
import pubStd from '@nl/publications/graphql/fragments/pub-std.gqlf';

const gql = {
    queries: {
        search,
        popular,
    },
    fragments: {
        feedStd,
        //feedPlus,
        pubStd,
    },
};

const api = createGraphQLAPI(config, gql);

export default api;
