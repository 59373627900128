import { fork, call, cancel, cancelled, take, put, takeLatest } from 'redux-saga/effects'
import { groupActions, createAPISaga } from '@lib/utils/redux';
import { actions } from './';
import api from './api';

const createGroup = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.createGroup, acts.createGroup);

    yield takeLatest(acts.createGroup.request, workerSaga);
};

const getGroups = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getGroups, acts.getGroups);

    yield takeLatest(acts.getGroups.request, workerSaga);
};

const updateGroup = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.updateGroup, acts.updateGroup);

    yield takeLatest(acts.updateGroup.request, workerSaga);
};

const deleteGroup = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.deleteGroup, acts.deleteGroup);

    yield takeLatest(acts.deleteGroup.request, workerSaga);
};

const addItem = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.addItem, acts.addItem);

    yield takeLatest(acts.addItem.request, workerSaga);
};

export default function* () {

    const sagas = [
        createGroup,
        getGroups,
        updateGroup,
        deleteGroup,
        addItem,
    ];

	for (let saga of sagas) {
		yield fork(saga);
	}
};
