import { createReducer } from '@lib/utils/redux';
import config from './config.json';
import actions from './actions';

const reducers =  {
    setGroupItems: (state, action) => {
        const items = action.payload.items;
        state.selectedItems = items;
    },
};

const reducer = createReducer(config, actions, reducers);

export default reducer;
