'use strict'

import React from 'react';
import { connect } from 'react-redux';
import s from './styles.scss';

class ImageComp extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			src: props.src,
		};
	}

	componentDidMount() {

		const { defaultSrc } = this.props;
		const img = new Image();

		img.onerror = () => {
			this.setState({ src: defaultSrc });
		};

		img.src = this.state.src;
	}

	render() {

		const { src } = this.state;
		const { width, height, className, styles } = this.props;

		return (
			<img
				className={ className }
				src={ src }
				width={ width }
				height={ height }
				style={ styles }
			/>
		);
	}
}

export default connect(
	(state, props) => ({
	}),
	{},
)(ImageComp);
