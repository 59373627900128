import {
	compose, curry, flip, groupBy, map, mapObjIndexed,
	pick, prepend, prop, values,
} from 'ramda';

export const groupFeedsByPublicationId = curry(compose(
	groupBy(prop('publicationId')),
	values,
	flip(pick)
));

export const groupFeedIdsByPublicationId = curry(compose(
	mapObjIndexed(map(prop('_id'))),
	groupFeedsByPublicationId
));
