import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import $modal from '@state/modal';
import $users from '@state/users';
import $publications from '@state/publications';

import Modal from '../templates/main';
import Image from '@ui/components/image';

import z from '@ui/styles/base.scss';
import s from './styles.scss';
import m from '../../styles.scss';

const zx = cn.bind(z);
const sx = cn.bind(s);
const mx = cn.bind(m);

const removeBookmarkFn = (
	removeBookmark,
	closeModal,
	user,
	story,
) => {

	removeBookmark({
		userId: user._id,
		storyId: story._id,
	});

	closeModal();
};

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
	}),
	{
		closeModal: $modal.actions.close,
		removeBookmark: $users.actions.removeBookmarkRequest,
	},
)(
	c(
		({ user, story, logoSrc, closeModal, removeBookmark }) => (
			<Modal
				header={
					() => <div>Remove this bookmark?</div>
				}
				body={
					() => (
						<div className={ s.storyRow }>
							<div className={ s.logoCont }>
								<Image
									className={ s.logo }
									src={ logoSrc }
									width={ 30 }
									defaultSrc='/imgs/newspaper.png'
								/>
							</div>
							<div className={ s.title }>{ story.title }</div>
						</div>
					)
				}
				footer={
					() => (
						<>
                            <button
								onClick={ closeModal }
								className={ mx('btn', 'btnCancel') }
							>
								No
							</button>
							<button
								onClick={
									() => removeBookmarkFn(
										removeBookmark,
										closeModal,
										user,
										story,
									)
								}
								className={ mx('btn', 'btnConfirm') }
							>
								Yes
							</button>
						</>
					)
				}
			/>
		)
	)
);
