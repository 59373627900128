import USER_DOMAIN_STD from '@nl/app/graphql/fragments/user-domain-std.gqlf';
import USER_STD_FRAG from '@nl/users/graphql/fragments/user-std.gqlf';
import FEED_STD_FRAG from '@nl/feeds/graphql/fragments/feed-std.gqlf';
import PUB_STD_FRAG from '@nl/publications/graphql/fragments/pub-std.gqlf';
import STORY_STD_FRAG from '@nl/stories/graphql/fragments/story-std.gqlf';

const USER_DOMAIN_FRAGS = `
${ USER_STD_FRAG }
${ FEED_STD_FRAG }
${ PUB_STD_FRAG }
${ STORY_STD_FRAG }
`;

const getUserDomain = (fields, fragments = USER_DOMAIN_FRAGS) => `
query GetUserDomain($userId: ID) {
    userDomainResult: getUserDomain(userId: $userId) {
        user { ...userStd }
        feeds { ...feedStd }
        publications { ...pubStd }
        stories { ...storyStd }
        feedIds
        tags {
            _id
            name
        }
    }
}
${ fragments }
`;

export default {
    getUserDomain,
};
