import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames/bind';

import { bindThis } from '@lib/utils/react';
import $modal from '@state/modal';
import $groups from '@state/groups';
import $users from '@state/users';

import Modal from '../templates/main';

import s from './styles.scss';
import m from '../../styles.scss';

const sx = cn.bind(s);
const mx = cn.bind(m);

class CreateGroupModal extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			groupName: '',
		};

		bindThis(this, [
			'onChangeGroupName',
			'onKeyPressGroupName',
			'createGroup'
		]);
	}
	
	onChangeGroupName(e) {
		this.setState({ groupName: e.target.value });
	}

	onKeyPressGroupName(e) {

		if (e.key == 'Enter') {
			this.createGroup();
		}
		return false;
	}

	createGroup() {

		const { createGroup, closeModal, loggedInUser } = this.props;
		const { groupName } = this.state;
		
		createGroup({
			input: {
				groupName,
				userId: loggedInUser._id
			}
		});

		closeModal();
	}

	render() {

		const { closeModal } = this.props;
		const { groupName } = this.state;

		return (
			<Modal
				header={
					() => <span className={ s.header }>Create a group</span>
				}
				body={
					() => (
                        <div className={ s.body }>
                            <div className={ s.formRow }>
                                <label className={ s.label }>Group Name</label>
                                <input
                                    type='text'
                                    className={ s.groupNameInput }
                                    name='groupName'
                                    autoFocus={ true }
                                    value={ groupName }
                                    onChange={ this.onChangeGroupName }
                                    onKeyPress={ this.onKeyPressGroupName }
                                />
                            </div>
                        </div>
					)
				}
				footer={
					() => (
						<>
							<button
								onClick={ closeModal }
								className={ mx('btn', 'btnCancel') }
							>
								Cancel
							</button>
							<button
								onClick={ this.createGroup }
								className={ mx('btn', 'btnConfirm') }
							>
								Ok
							</button>
						</>
					)
				}
			/>
		);
	}
}

export default connect(
	(state, props) => ({
		loggedInUser: $users.selectors.getLoggedInUser(state, props),
	}),
	{
		closeModal: $modal.actions.close,
		createGroup:  $groups.actions.createGroupRequest,
	},
)(CreateGroupModal);
