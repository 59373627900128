import io, { Manager } from 'socket.io-client';

export default class {

    constructor(env, userId) {

        this.manager = new Manager(
            env.URL,
            {
                path: env.PATH,
                query: {
                    userId,
                },
                autoConnect: false,
                reconnection: false,
            }
        );
        this.sockets = {};
    }

    addSocket(socket) {
        this.sockets[ socket.nsp ] = socket;
    }

    removeSocket(nsp) {
        delete this.sockets[ nsp ];
    }

    getSocket(nsp) {
        return this.sockets[ nsp ];
    }
};
