import { combineReducers } from 'redux';
import { reducer as app } from '@state/app';
import { reducer as modal } from '@state/modal';
import { reducer as auth } from '@state/auth';
import { reducer as socketio } from '@state/socketio';
import { reducer as search } from '@state/search';
import { reducer as feeds } from '@state/feeds';
import { reducer as feed } from '@state/feed';
import { reducer as publications } from '@state/publications';
import { reducer as stories } from '@state/stories';
import { reducer as users } from '@state/users';
import { reducer as tags } from '@state/tags';
import { reducer as groupPanel } from '@state/group-panel';

const reducers = {
    app,
    modal,
    auth,
    socketio,
    search,
    feeds,
    feed,
    publications,
    feed,
    stories,
    users,
    tags,
    groupPanel,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
