export default class {

    constructor(history) {
        this.history = history;
        this.listeners = [];
    }

    addListener(fn) {
        this.listeners.push(fn);
    }

    triggerLocation(ctx) {
        this.listeners.forEach(listener => listener(ctx));
    }

    listen() {
        this.history.listen(this.triggerLocation.bind(this));
    }
}
