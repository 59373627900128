import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';

import { bindThis } from '@lib/utils/react';

import $modal from '@state/modal';
import $users from '@state/users';
import $feeds from '@state/feeds';
import $publications from '@state/publications';
import $socketio from '@state/socketio';
import $groups from '@state/groups';

import Modal from '../templates/subscribe';

import z from '@ui/styles/base.scss';
import s from './styles.scss';
import m from '../../styles.scss';

const zx = cn.bind(z);
const sx = cn.bind(s);
const mx = cn.bind(m);

const ALL = 0;
const PICK = 1;

class SubscribeModal extends React.Component {

	constructor(props) {

		super(props);

		const { modalProps: { hasSub, subbedFeeds }, feeds, sub } = props;

		this.state = {
			mode: (hasSub && sub.pick) ? PICK: ALL,
			checked: hasSub ?
				subbedFeeds.map(f => f._id) :
				[],
		};

		bindThis(this, [
			'subscribe',
			'unsubscribe',
			'handleClick',
			'handlePick',
			'isChecked',
		]);
	}
	
	subscribe(pick) {

		const {
			subscribe,
			closeModal,
			modalProps: { nsp },
			user,
			publication,
			socketIds,
		} = this.props;

		let feeds = [];

		if (pick) {
			feeds = this.state.checked.map(feedId => ({ feedId }));
		}

		subscribe({
			userId: user._id,
			socketId: socketIds[ nsp ],
			input: {
				publicationId: publication._id,
				feeds,
				groupId: null,
				pick,
			}
		});

		closeModal();
	}

	unsubscribe() {
		
		const {
			user,
			publication,
			socketIds,
			modalProps: { nsp },
			unsubscribe,
			closeModal,
		} = this.props;

		unsubscribe({
			userId: user._id,
			socketId: socketIds[ nsp ],
			publicationId: publication._id,
		});

		closeModal();
	}

	handleClick(feedId) {

		const { checked } = this.state;

		let newChecked;

		if (checked.includes(feedId)) {
			newChecked = checked.filter(id => id !== feedId);
		} else {
			newChecked = [ ...checked, feedId ];
		}

		this.setState({
			checked: newChecked,
		});
	}

	handlePick() {
		this.setState({ mode: PICK });
	}

	isChecked(feedId) {
		return this.state.checked.includes(feedId);
	}

	render() {

		const { user, publication, feeds, logoSrc, closeModal, hasSub } = this.props;
		const { mode, checked } = this.state;

		const subText = hasSub ? 'Update' : 'Subscribe';

		const Mode1 = () => (
			<div>
				<button
					onClick={ () => this.subscribe(false) }
					className={ sx('btnSubscribe') }
				>
					Subscribe to All
				</button>
				<div className={ s.orOther } >
					<div className={ s.or }>or</div>
					<div className={ s.otherOption } onClick={ this.handlePick }>
                        Choose feeds
					</div>
				</div>
			</div>
		);

		const Mode2 = () => (
			<div className={ s.textCol }>
				<div className={ sx('row', 'feeds') }>
					{
						feeds.reduce(
							(feedEls, feed) => feed.aggregate ? feedEls : [
								...feedEls,
								(
									<div
										key={ feed._id }
										className={ s.row }
										onClick={
											e => this.handleClick(feed._id)
										}
									>
										<div className={ s.inputCont }>
											<input
												value={ feed._id }
												checked={ this.isChecked(feed._id) }
												type='checkbox'
												readOnly
											/>
										</div>
										<div className={ s.text }>
                                            { feed.title }
                                        </div>
									</div>
								)
							],
							[]
						)
					}
				</div>
				<button
					onClick={
						() => checked.length ?
							this.subscribe(true) :
							this.unsubscribe()
					}
					className={ sx('btnSubscribe') }
				>
					{ subText }
				</button>
				<div className={ s.orOther } >
					<div
						className={ s.back }
						onClick={ () => this.setState({ mode: ALL }) }
					>
                        <span className={ cn(zx('materialOutlined'), sx('backIcon')) }>
                            arrow_left_alt
                        </span>
                        <span>Back</span>
					</div>
				</div>
			</div>
		);

		const Comp = mode === ALL ? <Mode1 /> : <Mode2 />;

		return (
			<Modal
				header={
					() => (
						<div className={ s.header }>
							<div className={ s.pubRow } >
								<div className={ s.logoCont }>
									<img
										src={ logoSrc }
									/>
								</div>
								<div className={ s.pubTitle }>
                                    { publication.name }
                                </div>
							</div>
						</div>
					)
				}
				body={
					() => {

						return feeds.length > 1 ? (
							<div className={ s.pubBody }>
								{ Comp }
							</div>
						) :
						(
							<div className={ s.pubBody }>
								ALL
							</div>
						)
					}
				}
			/>
		);
	}
}

export default connect(
	(state, props) => ({
		user: $users.selectors.getUser(state, props),
		sub: $users.selectors.getPublicationSubscription(state, props),
		socketIds: $socketio.selectors.getSocketIdsByNsp(state, props),
		modalProps: $modal.selectors.getProps(state, props),
		publication: $publications.selectors.getPublication(state, props),
		feeds: $feeds.selectors.makeGetFeedsByPublicationId()(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
	}),
	{
		subscribe: $users.actions.subscribeRequest,
		unsubscribe: $users.actions.unsubscribeRequest,
		closeModal: $modal.actions.close,
	},
)(SubscribeModal);
