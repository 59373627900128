import * as r from 'ramda';
import { createReducer } from '@lib/utils/redux';
import config from '@nl/feeds/config.json';
import actions from './actions';
import { actions as app } from '@state/app';
import { actions as search } from '@state/search';

const reducerImports = {
    app,
    search,
};

const reducers = {
	byId: {
		'app.getUserDomainSuccess': (state, action) => {
            const { payload } = action;
            const { userDomainResult: { feeds }} = payload;
			feeds.forEach(feed => {
				state[ feed._id ] = feed;
			});
		},
		'search.popularSuccess': (state, action) => {
            const { payload } = action;
            const { result } = payload;
			result.feeds.forEach(feed => {
				state[ feed._id ] = feed;
			});
		},
		'search.searchSuccess': (state, action) => {
            const { payload } = action;
            const { result } = payload;
			result.feeds.forEach(feed => {
				state[ feed._id ] = feed;
			});
		},
	},
	allIds: {
		'app.getUserDomainSuccess': (state, action) => {

            const { payload } = action;
            const { userDomainResult: { feeds }} = payload;
			const feedIds = r.compose(
				r.map(r.prop('_id')),
			)(feeds);

			const ids = new Set([ ...state, ...feedIds ]);

			state.splice(0, state.length, ...ids);
		},
		'search.popularSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const feedIds = r.compose(
				r.map(r.prop('_id')),
				r.prop('feeds')
			)(result);

			const ids = new Set([ ...state, ...feedIds ]);

			state.splice(0, state.length, ...ids);
		},
		'search.searchSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;

			const feedIds = r.compose(
				r.map(r.prop('_id')),
				r.prop('feeds')
			)(result);

			const ids = new Set([ ...state, ...feedIds ]);

			state.splice(0, state.length, ...ids);
		},
	},
	idsByPublicationId: {
		'app.getUserDomainSuccess': (state, action) => {

            const { payload } = action;
            const { userDomainResult: { feeds }} = payload;

			feeds.forEach(feed => {
				if (!(feed.publicationId in state)) {
					state[ feed.publicationId ] = [];
				}
				if (!state[ feed.publicationId ].includes(feed._id)) {
					state[ feed.publicationId ].push(feed._id);
				}
			});
		},
		'search.popularSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const { feeds } = result;

			for (let feed of feeds) {
				if (!(feed.publicationId in state)) {
					state[ feed.publicationId ] = [];
				}
				if (!state[ feed.publicationId ].includes(feed._id)) {
					state[ feed.publicationId ].push(feed._id);
				}
			}
		},
		'search.searchSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const { feeds } = result;

			for (let feed of feeds) {
				if (!(feed.publicationId in state)) {
					state[ feed.publicationId ] = [];
				}
				if (!state[ feed.publicationId ].includes(feed._id)) {
					state[ feed.publicationId ].push(feed._id);
				}
			}
		},
        createFeedSuccess: (state, action) => {
            const { payload } = action;
            const { feed } = payload;
			state[ feed.publicationId ] = r.compose(
				r.uniq,
				r.append(feed._id),
				r.prop(feed.publicationId),
			)(state);
		},
        deleteFeedSuccess: (state, action) => {
            const { payload } = action;
            const { feed } = payload;o
			const { _id, publicationId } = feed;

			if (r.has(publicationId, state)) {
				state[ publicationId ] = r.reject(
                    r.equals(_id),
                    state[ publicationId ]
                );
			}
		},
        getFeedSuccess: (state, action) => {
            const { payload } = action;
            const { feed } = payload;
			state[ feed.publicationId ] = feed._id;
		},
        getFeedsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds }= payload;

            return r.compose(
                r.mapObjIndexed(r.uniq),
                r.mergeWith(r.concat, state),
                r.mapObjIndexed(r.map(r.prop('_id'))),
                r.groupBy(r.prop('publicationId'))
            )(feeds);
        },
        getFeedsByIdsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds } = payload;

            return r.compose(
                r.mapObjIndexed(r.uniq),
                r.mergeWith(r.concat, state),
                r.mapObjIndexed(r.map(r.prop('_id'))),
                r.groupBy(r.prop('publicationId'))
            )(feeds)
        },
	},
    publicationIdsByFeedId: {
		getFeedSuccess: (state, action) => {

            const { payload } = action;
            const { feed } = payload;

			state[ feed._id ] = feed.publicationId;
		},
        getFeedsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds } = payload;

			feeds.forEach(feed => {
				state[ feed._id ] = feed.publicationId;
			});
		},
        getFeedsByIdsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds } = payload;

			feeds.forEach(feed => {
				state[ feed._id ] = feed.publicationId;
			});
		},
	},
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
