import { cloneElement } from 'react';
import AppLogo from '@ui/components/app-logo';
import Header from '@ui/components/header/main';
import MenuBtn from '@ui/components/menu-btn';
import Nav from '@ui/components/header/nav';
import CenterList from '@ui/components/list';
import SearchSelect from '../search-select';
import FeedLayoutBtns from '../feed-layout-btns';
import FeedView from '../feed-view';

const headerRight = () => <div />;
const Center = () => <CenterList list={ [ SearchSelect ] } />;

const getLeft = ctx => {
    return () => {
        if (ctx.responsive.md) {
            return (
                <>
                    <MenuBtn />
                    <Nav />
                </>
            );
        }
        return <MenuBtn />;
    };
};

const getCenter = ctx => {

    let items = [];

    if (ctx.responsive.md) {
        /*items = [
            <SearchSelect
                inputModes={ ctx.inputModes }
                mode={ ctx.inputMode }
                setMode={ ctx.setInputMode }
                setSearchTouched={ ctx.setSearchTouched }
            />,
            <FeedLayoutBtn icon={ ctx.feedLayout.icon } rotate={ ctx.rotate } />,
        ];*/
        items = [
            <FeedView
                menuOpen={ ctx.menuOpen }
                setMenuOpen={ ctx.setMenuOpen }
                layouts={ ctx.feedLayouts }
                toggleLayout={ ctx.toggleLayout }
            />,
        ];
    } else {
        if (ctx.mobile.visible === 'search') {
            items = [
                <Search inputRef={ inputRef } />,
            ];
        } else {
            items = [
                <AppLogo />,
            ];
        }
    }

    return () => items.map((Comp, key) => cloneElement(Comp, { key }));
};

const getRight = ctx => {

    if (!ctx.responsive.md && ctx.mobile.visible !== 'search') {
        return () => (
            <FeedLayoutBtns
                layouts={ ctx.feedLayouts }
                toggle={ ctx.toggleLayout }
            />
        );
    }

    return () => null;
};

export default ctx => {

    return (
        <Header
            left={ getLeft(ctx) }
            center={ getCenter(ctx) }
            right={ getRight(ctx) }
        />
    );
};
