import { connect } from 'react-redux';
import { useState } from 'react';
import cn from 'classnames/bind';

import $users from '@state/users';
import $publications from '@state/publications';
import $modal from '@state/modal';

import Feed from './feed';
import Image from '@ui/components/image';

import z from '@ui/styles/base.scss';
import s from './styles.scss';

const sx = cn.bind(s);
const zx = cn.bind(z);

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		feeds: $users.selectors.getUserPublicationFeeds(state, props),
		publication: $publications.selectors.getPublication(state, props),
		logoSrc: $publications.selectors.getLogoSrc(
            state,
            {
                ...props,
                dim: '200',
                bg: 'dark'
            }
        ),
	}),
	{
		openModal: $modal.actions.open,
	},
)(
	({ user, subscription, publication, logoSrc, feeds, openModal }) => {

        const [ expanded, setExpanded ] = useState(false);

        const arrowPos = expanded ? 'arrow_drop_down' : 'arrow_right';

        return (
            <div className={ s.root }>
                <div
                    className={
                        sx(
                            'header',
                            {
                                headerClickable: (
                                    subscription.pick &&
                                    feeds &&
                                    feeds.length
                                )
                            }
                        )
                    }
                    onClick={ () => setExpanded(!expanded) }
                >
                    <div
                        className={ s.expandCont }
                        onClick={ () => setExpanded(!expanded) }
                    >
                    { subscription.pick && feeds && feeds.length &&
                        <span className={ cn(zx('materialOutlined'), sx('expand')) }>
                            { arrowPos }
                        </span>
                    }
                    </div>
                    <div className={ s.logoCont }>
                        <Image
                            className={ s.logo }
                            src={ logoSrc }
                            width={ 25 }
                            defaultSrc='/imgs/newspaper.png'
                        />
                    </div>
                    <div className={ s.title }>
                        { publication?.name }
                    </div>
                    <div
                        className={ s.unsub }
                        onClick={
                            () => openModal({
                                type: 'unsubscribePublication',
                                props: {
                                    publicationId: publication?._id,
                                    userId: user?._id
                                },
                            })
                        }
                    >
                        <span className={ cn(zx('materialOutlined'), sx('close')) }>
                            close_small
                        </span>
                    </div>
                </div>
                { expanded && subscription.pick && 
                    <div className={ s.feeds }>
                        {
                            feeds && !!feeds.length && feeds.map(feed => (
                                <Feed
                                    key={ feed._id }
                                    feed={ feed }
                                    expanded={ expanded }
                                    toggleExpand={ () => setExpanded(!expanded) }
                                />
                            ))
                        }
                    </div>
                }
            </div>
        );
    }
);
