import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import $app from '@state/app';
import $users from '@state/users';
import $feeds from '@state/feeds';
import $publications from '@state/publications';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		responsive: $app.selectors.getResponsive(state, props),
		feed: $feeds.selectors.makeGetFeedById()(state, props),
		publication: $publications.selectors.makeGetPublicationByFeedId()(state, props),
		logoSrc: $publications.selectors.getLogoSrc(
            state,
            { ...props, dim: '200', bg: 'dark' }
        ),
		subs: $users.selectors.getSubsByPubIds(state, props),
	}),
	{
		addBookmark: $users.actions.addBookmarkRequest,
	},
)(
	c(
		({
			responsive,
			user,
			story,
			feed,
			feedId,
			publication,
			logoSrc,
			addBookmark,
			localeDateString,
			momentDateString,
			layout,
		}) => (
			<div className={ sx('root', { mobile: !responsive.md }) }>
                {
					responsive.md && (
						<div className={
                            sx('col', 'imgCont', { missing: !story.image?.url }) }
                        >
							{ !!story.image.url &&
								<img src={ story.image.url } className={ s.img }/>
							}
						</div>
					)
				}
				<div className={ sx('col', 'content') }>
					<div className={ sx('subHeader', { mobile: !responsive.md }) }>
						<span className={ s.logo }>
							<img
								className={ s.pubLogo }
								src={ logoSrc }
							/>
						</span>
						<span
							className={ s.pubName }
							title={ feed.title }
						>
							{ publication.name }
						</span>
						<span className={ s.date } title={ localeDateString }>
							{ momentDateString }
						</span>
						<span
							className={ cn(mx('icon-bookmark'), sx('bookmark')) }
							onClick={
								() => addBookmark({ userId: user._id, storyId: story._id })
							}
						/>
					</div>
					<div
						className={ sx('storyTitle', { mobile: !responsive.md }) }
						title={ story.title }
					>
						<a href={ story.link } target='_blank'>
							{ story.title }
						</a>
					</div>
					<div className={ s.description }>
						{ story.summary || story.description }
					</div>
				</div>
				
			</div>
		),
	)
);
