export default {
    searchSuccess: (state, action) => {

        const { payload } = action;
        const { result } = payload;

        return result.publications.map(p => p._id);
    },
    popularSuccess: (state, action) => {

        const { payload } = action;
        const { result } = payload;

        return result.publications.map(p => p._id);
    },
};
