import createGraphQLAPI from '@lib/utils/graphql-api';
import config from '@nl/feed/config.json';
import nextPage from '@nl/feed/graphql/queries/next-page.gqlq';
import prevPage from '@nl/feed/graphql/queries/prev-page.gqlq';

const gql = {
    queries: {
        nextPage,
        prevPage,
    },
    fragments: {},
};

const api = createGraphQLAPI(config, gql);

export default api;
