import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	append, compose as c, difference, equals, filter, findIndex, flip, forEach,
	has, ifElse, isEmpty, keys, mapObjIndexed, not, objOf, omit, prop, propEq,
	reduce, reject, transduce,
} from 'ramda';

import { bindThis } from '@lib/utils/react';
import cn from 'classnames/bind';

import $modal from '@state/modal';
import $groups from '@state/groups';
import $users from '@state/users';
import $userGroups from '@state/user-groups';
import $groupPanel from '@state/group-panel';

import Header from './ui/header';
import ItemsSelect from './ui/item-select';
import GroupItem from './ui/item';
import Option from '@ui/components/react-select/option';
import MultiValueLabel from '@ui/components/react-select/multi-value-label';

import i from '@ui/styles/icomoon.css';
import s from './styles.scss';

const sx = cn.bind(s);
const si = cn.bind(i);

const { groupItemsAfterAdd, groupItemsAfterRemove } = $groupPanel.utils;

const onChange = (currentValue, setGroupItems) =>
	(selectedOptions, selectEvent) => {

		const { action } = selectEvent;

		const _setGroupItems = c(
			setGroupItems,
			objOf('items'),
		);

		switch (action) {

			case 'select-option': return c(
				_setGroupItems,
				groupItemsAfterAdd(currentValue),
				prop('option'),
			)(selectEvent);

			case 'remove-value': return c(
				_setGroupItems,
				groupItemsAfterRemove(currentValue),
				prop('removedValue')
			)(selectEvent);
		}
	};

const updateMembers = (
	userId,
	group,
	selectedItems,
	updateGroup,
	toggleEditMode
) => {

	updateGroup({
		userId,
		groupId: group._id,
		input: {
			name: group.name,
			items: selectedItems,
		},
	});

	toggleEditMode('members');
};

export default connect(
	(state, props) => ({
		loggedInUser: $users.selectors.getLoggedInUser(state, props),
		group: $userGroups.selectors.getGroupById(state, props),
		options: $groupPanel.selectors.makeGetOptions()(state, props),
		currentValue: $groupPanel.selectors.makeGetCurrentValue()(state, props),
		selectedItems: $groupPanel.selectors.makeGetSelectedItems()(state, props),
	}),
	{
		setGroupItems: $groupPanel.actions.setGroupItems,
		updateGroup: $groupPanel.actions.updateGroupRequest,
	},
)(
	c(
		({
			group,
			loggedInUser,
			options,
			selectedItems,
			currentValue,
			updateGroup,
			setGroupItems,
		}) => {

			const [ editModes, setEditModes ] = useState({
				name: false,
				members: false,
			});

			const toggleEditMode = field => {
				setEditModes(prevState => ({
					...prevState,
					[ field ]: !prevState[ field ],
				}))
			};

			const onChangeFn = onChange(currentValue, setGroupItems);

			if (!loggedInUser) {
				return <div />;
			}

            let body;

            if (editModes.members) {
                body = (
                    <ItemsSelect
						userId={ loggedInUser._id }
						groupId={ group._id }
						options={ options }
						currentValue={ currentValue }
						onChangeFn={ onChangeFn }
					/>
                );
            } else {
                if (group.items.length) {
                    body = (
                        <ul className={ sx('groupItemsList') }>{
                            group.items.map(item => (
                                <GroupItem
                                    key={ item.id }
                                    feedId={ item.type === 'feed' ? item.id : null }
                                    publicationId={ item.type === 'publication' ? item.id : null }
                                />
                            ))
                        }</ul>
                    );
                } else {
                    body = (
                        <div className={ s.empty }>No items</div>
                    );
                }
            }

			return (
				<li className={ s.root }>
					<Header
						userId={ loggedInUser._id }
						groupId={ group._id }
						editModes={ editModes }
						toggleEditMode={ toggleEditMode }
						openModal={ $modal.actions.openModal }
						updateMembers={ () => updateMembers(
							loggedInUser._id,
							group,
							selectedItems,
							updateGroup,
							toggleEditMode,
						)} 
					/>
					{ body }
				</li>
			);
		}
	)
);
