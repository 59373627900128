import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import cn from 'classnames/bind';

import $app from '@state/app';
import $auth from '@state/auth';
import $users from '@state/users';
import $stories from '@state/stories';
import $socketio from '@state/socketio';
import $feed from '@state/feed';

import StoryTime from './ui/story-time';
import Compact from './ui/story-compact';
import Detailed from './ui/story-detailed';

import s from './styles.scss';

const sx = cn.bind(s);

export default connect(
	(state, props) => ({
        user: $users.selectors.getLoggedInUser(state, props),
		responsive: $app.selectors.getResponsive(state, props),
		accessToken: $auth.selectors.getAccessToken(state, props),
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
		stories: $feed.selectors.getStories(state, props),
        lastStoryId: $feed.selectors.getLast(state, props),
	}),
	{
		startFeed: $feed.actions.start,
        getPrevFeedPage: $users.actions.getPrevFeedPageRequest,
	}
)(
	c(
		({
			user,
			accessToken,
			stories,
			sockets,
			responsive,
			layout,
            lastStoryId,
			startFeed,
            getPrevFeedPage,
		}) => {

            if (!user) return null;

			useEffect(() => {
				if (user && accessToken && sockets['/poller']) {
					const a = startFeed({ userId: user._id, accessToken });
				}
			}, [ user, accessToken, sockets ]);

			if (!stories.length) {
				return (
					<div className={ s.emptyList }>
						Nothing to see
					</div>
				);
			}

			const El = layout.id === 'compact' ? Compact : Detailed;

			return !!stories.length && (
				<div className={ sx('root', { mobile: !responsive.md }) }>
					<div className={ sx('cont', { mobile: !responsive.md }) }>
						<div className={ sx('ctrls') }>
						</div>
						<div className={ sx('list') }>
                        {
                            stories.map(s => {
                                return (
                                    <StoryTime
                                        key={ s._id }
                                        story={ s }
                                        feedId={ s.feedId }
                                        StoryEl={ El }
                                    />
                                );
                            })
                        }
						</div>
                        <div
                            className={ s.more }
                            onClick={
                                () => getPrevFeedPage({
                                    userId: user._id,
                                    storyId: lastStoryId,
                                    pageOpts: {},
                                })
                            }
                        >
                            <Fa icon='fa-solid fa-chevron-down' />
                        </div>
					</div>
				</div>
			);
		}
	)
);
