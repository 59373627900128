import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import Select from 'react-select';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import $modal from '@state/modal';
import $users from '@state/users';
import $feeds from '@state/feeds';
import $publications from '@state/publications';
import $socketio from '@state/socketio';

import Modal from '../templates/main';

import s from './styles.scss';
import m from '../../styles.scss';

const sx = cn.bind(s);
const mx = cn.bind(m);

const unsubscribeFn = (
	unsubscribe,
	closeModal,
	user,
	feed,
	socketIds,
) => {

	unsubscribe({
		userId: user._id,
		socketId: socketIds[ '/' ],
		id: feed._id,
		type: 'feed',
	});

	closeModal();
};

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		socketIds: $socketio.selectors.getSocketIdsByNsp(state, props),
		feed: $feeds.selectors.makeGetFeedById()(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
	}),
	{
		closeModal: $modal.actions.close,
		unsubscribe: $users.actions.unsubscribeRequest,
	},
)(
	c(
		({ user, feed, logoSrc, socketIds, closeModal, unsubscribe }) => (
			<Modal
				header={
					() => <span>Remove this feed?</span>
				}
                body={
                    () => (
                        <div className={ s.body }>
							<div className={ s.logoCont }>
								<img
									src={ logoSrc }
                                    width={ 30 }
								/>
							</div>
							<span className={ s.title }>{ feed.title }</span>
						</div>
                    )
                }
				footer={
					() => (
						<>
							<button
								onClick={ closeModal }
								className={ mx('btn', 'btnCancel') }
							>
								No
							</button>
							<button
								onClick={
									() => unsubscribeFn(
										unsubscribe,
										closeModal,
										user,
										feed,
										socketIds
									)
								}
								className={ mx('btn', 'btnConfirm') }
							>
								Yes
							</button>
						</>
					)
				}
			/>
		)
	)
);
