import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

const self = state => state.socketio;

export const getSocketId = createSelector(
    [
        self,
    ],
    prop('socketId')
);

export const getSocketIdsByNsp = createSelector(
    [
        self,
    ],
    prop('socketsByNamespace')
);

export default {
    getSocketId,
    getSocketIdsByNsp,
};
