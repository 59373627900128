import { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useFloating, offset, shift } from '@floating-ui/react';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import { rotateList } from '@lib/utils/misc';
import { useClickAway } from '@lib/utils/react';

import FeedViewCtrl from '../feed-view-ctrl';
import FeedViewSelector from '@ui/components/feed-view-selector';
import Search from '@mods/search';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
	}),
	{
	}
)(
	c(
		({ inputModes, mode, setMode, setSearchTouched }) => {

            const clickAwayRef = useRef(null);
			const searchInput = useRef(null);
			const menuRef = useRef(null);

			const [ modes, setOrder ] = useState(inputModes);

			const { x, y, refs, floatingStyles, strategy, update } = useFloating({
				placement: 'bottom',
				middleware: [ offset(5) ],
			});

			const [ menuOpen, setMenuOpen ] = useState(false);
			const [ height, setHeight ] = useState(null);
			const [ width, setWidth ] = useState(null);

            useClickAway(clickAwayRef, () => setMenuOpen(false));

			useEffect(()=>{
				if (mode.id === 'search') {
					searchInput.current.focus();
				}
			});

			useEffect(() => {
				if (menuRef.current) {
					setWidth(menuRef.current.offsetWidth);
				}
			}, [ menuRef ]);

			return (
				<div className={ s.root }>
					<div className={ s.btns }>
						{
							modes.map((modeObj, i) => (
								<div
									key={ modeObj.id }
									className={
										sx('btn', modeObj.className, { active: i === 0 })
									}
									onClick={
										() => {

											let order = modes;

											if (modeObj.id === mode.id) {
												order = rotateList(modes);
												setOrder(order);
												setMode(order[ 0 ]);
												return;
											}

											let newMode = mode;
											while (newMode.id !== modeObj.id) {
												order = rotateList(modes);
												newMode = order[ 0 ];
											}

											setOrder(order);
											setMode(newMode);

											if (newMode.id !== 'search') {
												setSearchTouched(false);
											}
										}
									}
								>
									<span className={ mx(modeObj.iconClass) } />
								</div>
							))
						}
					</div>
					<div className={ s.inputCont } ref={ menuRef }>
						<div className={ s.forRef } ref={ refs.setReference }>
						{
							mode.id === 'search' &&
								<Search
									inputRef={ searchInput }
									inputClass={ s.input }
									inputRootClass={ s.inputRoot }
									callback={ () => setSearchTouched(true) }
								/>
						}
						{
							mode.id === 'feed' &&
								<div className={ s.feedView } >
									<FeedViewCtrl
										clickHandler={ () => setMenuOpen(!menuOpen) }
									/>
								</div>
						}
						</div>
					</div>
					{
						menuOpen && mode.id === 'feed' &&
						<div
							ref={ refs.setFloating }
							style={ floatingStyles }
						>
                            <div className={ s.feedViewMenu } ref={ clickAwayRef }>
                                <FeedViewSelector />
                            </div>
						</div>
					}
				</div>
			);
		}
	)
);
