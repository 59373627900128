import cn from 'classnames/bind';
import s from './styles.scss';

const sx = cn.bind(s);

export default ({ left: Left, center: Center, right: Right }) => (
	<div className={ s.root }>
		<div className={ sx('segment', 'left') }>
            <Left />
		</div>
		<div className={ sx('segment', 'center') }>
			<Center />
		</div>
		<div className={ sx('segment', 'right') }>
            <Right />
		</div>
	</div>
);
