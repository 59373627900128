import { connect } from 'react-redux';
import $users from '@state/users';
import WorkspaceWrapper from '@ui/components/workspace';
import MobileMenu from '@ui/components/mobile-menu';
import Feed from '@ui/pages/user/ui/feed';

import s from './styles.scss';

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
    }),
    {
    }
)(ctx => {

    let workspace;

    if (
        (ctx.responsive.md && (ctx.inputMode.id === 'feed' || !ctx.searchTouched)) ||
        (!ctx.responsive.md && ctx.mobile.visible === 'feed')
    ) {
        workspace = <Feed
            userId={ ctx.user?._id }
            layout={ ctx.layout }
        />;
    } else if (!ctx.responsive.md && ctx.mobile.visible === 'menu') {
        workspace = <MobileMenu
            lastMenuItem={ lastMenuItem }
            menuData={ ctx.menuData }
        />;
    } else if (
        (ctx.inputMode.id === 'search' && ctx.searchTouched) ||
        (!ctx.responsive.md && ctx.mobile.visible === 'search')
    ) {
        //workspace = <DiscoverPortal userId={ user._id } />
    }

    return (
        <WorkspaceWrapper>
            <div className={ s.padded } />
            { workspace }
            <div className={ s.padded } />
        </WorkspaceWrapper>
    );
});
