import { createSelector } from '@reduxjs/toolkit';
import {
	always, applySpec, assoc, compose as c, concat, cond, defaultTo,
	difference, equals, filter, flip, has, identity, ifElse, isEmpty,
	isNil, keys, length, lt, map, mapObjIndexed, not, of, omit, pick,
	prepend, prop, propEq, reduce, reject, toPairs, transduce, tryCatch,
} from 'ramda';

import { selectors as $users } from '@state/users';
import { selectors as $feeds } from '@state/feeds';
import { selectors as $publications } from '@state/publications';

import { groupFeedIdsByPublicationId } from '@nl/feeds/lib';
import { makeSelectOpts } from '@lib/utils/react';

const makeGroupOpt = applySpec({
	type: always('group'),
	label: prop('name'),
	value: prop('_id'),
	obj: identity,
});

const makePubOpt = applySpec({
	type: always('publication'),
	label: prop('name'),
	value: prop('_id'),
	obj: identity,
});

const makeFeedOpt = applySpec({
	type: always('feed'),
	label: prop('title'),
	value: prop('_id'),
	obj: identity,
});

const makeGroupOpts = c(
	map(makeGroupOpt),
    flip(pick),
);

const makePubOpts = c(
	map(makePubOpt),
    flip(pick),
);

const makeFeedOpts = c(
	map(makeFeedOpt),
    flip(pick),
);

const groupFeedIdsByRemainingPublicationIds = (
	publicationIds,	//remaining pub ids
	feedIds,		//remaining feed ids
	allUserFeedsByPublicationId,		// user feeds by publication id
	feedsMap
) => c(
	mapObjIndexed(
		(feedIds, publicationId) => ifElse(
			c(
				//lt(1),
				//length,
				c(not,isEmpty),
				flip(prop)(allUserFeedsByPublicationId)
			),
			always(feedIds),
			always([])
		)(publicationId)
	),
	reject(isEmpty),
	pick(publicationIds),
	groupFeedIdsByPublicationId(feedsMap)
)(feedIds);

const makePubAndFeedOpts = (feeds, publications, feedIdsByPublicationId) => reduce(
	(opts, pubId) => concat(
		opts,
		prepend(
			makePubOpt(publications[ pubId ]),
			makeFeedOpts(feeds, feedIdsByPublicationId[ pubId ])
		)
	),
	[],
	keys(feedIdsByPublicationId)
);

const makeViewOpts = (groupOpts, pubAndFeedOpts) => [
	{
		label: 'Groups',
		options: groupOpts,
	},
	{
		label: 'Publications & Feeds',
		options: pubAndFeedOpts,
	},
];



export const getGroupId = (_, { groupId }) => groupId;

export const getGroups = createSelector(
    [
        $users.getLoggedInUser,
    ],
    (user) => user?.groups || [],
);

export const getGroupsMap = createSelector(
    [
        getGroups,
    ],
    reduce(
        (map, group) => {
            return {
                ...map,
                [ group._id ]: group,
            };
        },
        {}
    ),
);

export const getGroupById = createSelector(
    [
        getGroups,
        getGroupId,
    ],
    (groups, id) => groups.find(g => g._id === id),
);

export const getGroupItems = createSelector(
    [
        getGroupById,
    ],
    prop('items'),
);

export const getGroupIds = createSelector(
    [
        getGroups,
    ],
    map(prop('_id')),
);

export const getPublicationGroupItemIds = createSelector(
    [
        getGroupItems
    ],
    transduce(
        filter(propEq('type', 'publication')),
        (ids, item) => append(prop('id', item), ids),
        []
    ),
);

export const getGroupRemainingPublicationIds = createSelector(
    [
        $users.getSubscribedPublicationIds,
        getPublicationGroupItemIds,
    ],
    difference,
);

export const getFeedGroupItemIds = createSelector(
    [
        getGroupItems
    ],
    transduce(
        filter(propEq('type', 'feed')),
        (ids, item) => append(prop('id', item), ids),
        []
    ),
);

export const getGroupRemainingFeedIds = createSelector(
    [
        $users.getUserSubscriptionIds,
        getFeedGroupItemIds,
    ],
    difference
);

export const getViewRemainingGroupIds = createSelector(
    [
        getGroupIds,
        $users.getUserCurrentViewGroupIds,
    ],
    difference
);

export const getViewRemainingPublicationIds = createSelector(
    [
        $users.getSubscribedPublicationIds,
        $users.getUserCurrentViewPublicationIds,
    ],
    difference
);

export const getViewRemainingFeedIds = createSelector(
    [
        $users.getUserSubscriptionIds,
        $users.getUserCurrentViewFeedIds,
    ],
    difference
);

export const getUserGroupOpts = createSelector(
    [
        getGroupIds,
        getGroupsMap,
    ],
    (userGroupIds, groups) => makeSelectOpts(
        userGroupIds,
        groups,
        'name'
    )
);

export const unviewedFeedIdsByUnviewedPublicationIds = createSelector(
    [
        getViewRemainingPublicationIds,
        getViewRemainingFeedIds,
        $users.getUserFeedsByPublicationId,
        $feeds.getFeedsMap,
    ],
    groupFeedIdsByRemainingPublicationIds,
);

export const unselectedPubAndFeedOpts = createSelector(
    [
        $feeds.getFeedsMap,
        $publications.getPublicationsMap,
        unviewedFeedIdsByUnviewedPublicationIds,
    ],
    makePubAndFeedOpts,
);

export const subGroupsPubsFeeds = createSelector(
    [
        $users.getSubscriptions,
        getGroupIds,
        $users.getUserFeedsByPublicationId,
        getGroupsMap,
        $publications.getPublicationsMap,
    ],
    (subs, groupIds, feedsByPubIds, groups, publications) => ({
        groups: groupIds.map(id => groups[ id ]),
        pubsAndFeeds: c(
            reduce(
                (buckets, [ pubId, feeds ]) => ([
                    ...buckets,
                    [
                        publications[ pubId ],
                        subs.find(s => s.publicationId === pubId).pick ? feeds : [],
                    ],
                ]),
                [],
            ),
            toPairs,
        )(feedsByPubIds),
    })
);

export const groupsPubsFeeds = createSelector(
    [
        getGroupIds,
        $users.getUserFeedsByPublicationId,
        getGroupsMap,
        $publications.getPublicationsMap,
    ],
    (groupIds, feedsByPubIds, groups, publications) => ({
        groups: groupIds.map(id => groups[ id ]),
        pubsAndFeeds: c(
            reduce(
                (buckets, [ pubId, feeds ]) => ([
                    ...buckets,
                    [
                        publications[ pubId ],
                        feeds,
                    ],
                ]),
                [],
            ),
            toPairs,
        )(feedsByPubIds),
    })
);

export const unselectedGroupOpts = createSelector(
    [
        getGroupsMap,
        getViewRemainingGroupIds,
    ],
    makeGroupOpts
);

export const getRemainingViewOpts = createSelector(
    [
        unselectedGroupOpts,
        unselectedPubAndFeedOpts,
    ],
    makeViewOpts
);

export const selectedViewOpts = createSelector(
    [
        $users.getUserCurrentView,
        getGroups,
        $publications.getPublicationsMap,
        $feeds.getFeedsMap,
    ],
    (viewItems, groups, publications, feeds) => c(
        tryCatch(
            map(
                ({ id, type }) => cond(
                    [
                        [ equals('group'), () => {

                                const group = groups.find(g => g._id === id);

                                if (group) {
                                    return makeGroupOpt(group);
                                }
                                throw new Error('Missing group');
                            }
                        ],
                        [ equals('feed'), () => {
                                const feed = prop(id, feeds);

                                if (feed) {
                                    return makeFeedOpt(feed);
                                }
                                throw new Error('Missing feed');
                            }
                        ],
                        [ equals('publication'), () => {
                                const publication = prop(id, publications);

                                if (publication) {
                                    return makePubOpt(publication);
                                }
                                throw new Error('Missing publication');
                            }
                        ],
                    ]
                )(type),
            ),
            x => {
                console.log('Missing some options');
                return [];
            }
        )
    )(viewItems)
);

export const selectedGroupOpts = createSelector(
    [
        getGroupItems,
        $publications.getPublicationsMap,
        $feeds.getFeedsMap,
    ],
    (items, publications, feeds) => map(
        ({ id, type }) => cond(
            [
                [ equals('feed'), always(makeFeedOpt(prop(id, feeds))) ],
                [ equals('publication'), always(makePubOpt(prop(id, publications))) ],
            ]
        )(type),
        items || []
    )
);

export const makeSelectedGroupOpts = () => createSelector(selectedGroupOpts, identity);

export default {
    getGroupId,
    getGroups,
    getGroupsMap,
    getGroupById,
    getGroupItems,
    getGroupIds,
    getPublicationGroupItemIds,
    getGroupRemainingPublicationIds,
    getFeedGroupItemIds,
    getGroupRemainingFeedIds,
    getViewRemainingGroupIds,
    getViewRemainingPublicationIds,
    getViewRemainingFeedIds,
    getUserGroupOpts,
    unviewedFeedIdsByUnviewedPublicationIds,
    unselectedPubAndFeedOpts,
    subGroupsPubsFeeds,
    groupsPubsFeeds,
    unselectedGroupOpts,
    getRemainingViewOpts,
    selectedViewOpts,
    selectedGroupOpts,
    makeSelectedGroupOpts,
};
