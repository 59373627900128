import { useState } from 'react';
import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';

import $app from '@state/app';
import AppLayout from '@ui/layouts/app';
import Private from '@ui/components/private';
import Header from './ui/header';
import Workspace from './ui/workspace';
import FeedViewSelector from '@ui/components/feed-view-selector';
import GroupsTab from '@ui/components/groups-tab';
import SubscriptionsTab from '@ui/components/subscriptions-tab';
import BookmarksTab from '@ui/components/bookmarks-tab';

import $users from '@state/users';

import z from '@ui/styles/base.scss';

const feedLayouts = [
	{
		id: 'compact',
        active: false,
        title: 'Compact',
        icon: {
            //type: 'font-awesome',
            //className: 'fa-solid fa-table-list',
            type: 'material-ui',
            className: z.materialOutlined,
            content: 'format_list_bulleted',
        },
	},
	{
		id: 'detailed',
        active: false,
        title: 'Detailed',
        icon: {
            //type: 'font-awesome',
            //className: 'fa-solid fa-table-columns fa-rotate-90',
            //rotate: 270,
            type: 'material-ui',
            className: z.materialRounded,
            content: 'lists',
        },
	}
];

const inputModes = [
	{
		id: 'feed',
		className: 'btnList',
		iconClass: 'icon-list',
	},
	{
		id: 'search',
		className: 'btnSearch',
		iconClass: 'icon-search',
	},
];

const menuData = [
	{
		id: 'home',
		title: 'Home',
		label: 'Home',
		icon: 'icon-home',
		//link: ({ user }) => `/@${ user.username }`,
		mode: 'feed',
	},
	{
		id: 'search',
		title: 'Search',
		label: 'Search',
		icon: 'icon-search',
		//component: <Search />,
		mode: 'search',
	},
	{
		id: 'currentView',
		title: 'Current View',
		label: 'Current View',
		icon: 'icon-eye',
		component: <FeedViewSelector mobile={ true } />,
	},
	{
		id: 'groups',
		title: 'Groups',
		label: 'Groups',
		icon: 'icon-folder',
		component: <GroupsTab />,
	},
	{
		id: 'subscriptions',
		title: 'Subscriptions',
		label: 'Subscriptions',
		icon: 'icon-rss',
		component: <SubscriptionsTab />,
	},
	{
		id: 'bookmarks',
		title: 'Bookmarks',
		label: 'Bookmarks',
		icon: 'icon-bookmarks',
		component: <BookmarksTab />,
	},
	/*{
		id: 'userSettings',
		title: 'Settings',
		label: 'Settings',
		icon: 'icon-equalizer',
		component: <div />,
		component: <UserSettings
			userId={ user._id }
			username={ user.username }
			email={ user.email }
		/>
	},*/
];

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		mobile: $app.selectors.getMobile(state, props),
		lastPushed: $app.selectors.getMobileMenuLastPushed(state, props),
		responsive: $app.selectors.getResponsive(state, props),
        feedLayoutStates: $app.selectors.getFeedLayouts(state, props),
        settings: $users.selectors.getSettings(state, props),
    }),
    {
        updateSettings: $users.actions.updateSettingsRequest,
    }
)(
    c(
        ({
            user,
            mobile,
            lastPushed,
            responsive,
            feedLayoutStates,
            settings,
            updateSettings,
        }) => {

            const [ inputMode, setInputMode ] = useState(inputModes[ 0 ]);
			const [ searchTouched, setSearchTouched ] = useState(false);
			const [ _feedLayouts, setFeedLayouts ] = useState(
                feedLayouts.reduce(
                    (layouts, layout) => [
                        ...layouts,
                        {
                            ...layout,
                            active: settings.feedLayout === layout.id,
                        },
                    ],
                    []
                )
            );

            const findActiveLayout = layouts => layouts.find(l => l.active);

            const [ activeLayout, setActiveLayout ] = useState(
                findActiveLayout(_feedLayouts)
            );
			const [ menuOpen, setMenuOpen ] = useState(false);

            let lastMenuItem;

            if (lastPushed) {
				lastMenuItem = menuData.find(m => m.id === lastPushed);
			}

            const toggleLayout = id => {
                setFeedLayouts(_feedLayouts.reduce(
                    (layouts, layout) => [
                        ...layouts,
                        {
                            ...layout,
                            active: layout.id === id,
                        },
                    ],
                    []
                ));
                setActiveLayout(_feedLayouts.find(l => l.id === id));
                updateSettings({
                    userId: user._id,
                    settings: { ...settings, feedLayout: id },
                });
            };

            const props = {
                mobile,
                lastPushed,
                responsive,
                inputMode,
                inputModes,
                setInputMode,
                searchTouched,
                setSearchTouched,
                lastMenuItem,
                menuData,
                toggleLayout,
                feedLayouts: _feedLayouts,
                layout: activeLayout,
            };

            const headerProps = {
                mobile,
                responsive,
                feedLayouts: _feedLayouts,
                toggleLayout,
                menuOpen,
                setMenuOpen,
            };

            return (
                <Private>
                    <AppLayout
                        header={() => (
                            <Header { ...headerProps } />
                        )}
                        workspace={() => (
                            <Workspace { ...props } />
                        )}
                    />
                </Private>
            );
        }
    )
);
