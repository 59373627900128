import React from 'react';
import { connect } from 'react-redux';
//import { Redirect } from 'react-router-dom';
//import history from '@/history';
import cn from 'classnames/bind';

//import history from '../../history';

import app from '@state/app';
import auth from '@state/auth';
//import { withAll } from 'reduxUtils';
//import authOps from 'modules/authentication/ops';
//import modalOps from 'modules/modal/ops';
//import respSels from 'modules/responsive/selectors';
//import { DeviceContext, devices } from 'deviceContext';

import s from './styles.scss';

const sx = cn.bind(s);

const loginErrMsg = 'Incorrect username/passphrase combination';

class LoginPage extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			username: '',
			passphrase: '',
			errorMsg: '',
            focusUsername: false,
            focusPassphrase: false,
		};
	}

	componentDidMount() {
		this.usernameInput.focus();
	}

    onUsernameFocus(e) {
        this.setState({ focusUsername: true });
    }

    onUsernameBlur(e) {
        this.setState({ focusUsername: false });
    }

    onPassphraseFocus(e) {
        this.setState({ focusPassphrase: true });
    }

    onPassphraseBlur(e) {
        this.setState({ focusPassphrase: false });
    }

	onUsernameChange(e) {
		this.setState({ username: e.target.value });
	}

	onPassphraseChange(e) {
		this.setState({ passphrase: e.target.value });
	}

	async onLogin(e) {

		e.preventDefault();
		const { login } = this.props;
		//const { fetch } = ctx;
		const { username, passphrase } = this.state;

		const res = await login({ username, passphrase });

		/*if (errors && errors.length) {
			this.setState({ errorMsg: loginErrMsg });
		} else {
			history.push({ pathname: `user/${ username }` });
		}*/
	}

	/*forgotPassphrase(e) {

		e.preventDefault();
		const { openModal } = this.props;
		openModal({
			type: 'forgotPassphrase',
			props: {},
		});
	}

	forgotUsername(e) {

		e.preventDefault();
		const { openModal } = this.props;
		openModal({
			type: 'forgotUsername',
			props: {},
		});
	}*/

	render() {

		let { isLoggedIn, responsive } = this.props;
        //const isLoggedIn = false;
		const { username, passphrase, errorMsg, focusUsername, focusPassphrase } = this.state;

		return (
			<div className={ cn(sx('root', { mobile: !responsive.md })) }>
				<form className={ sx('loginForm') }>
					<div className={ sx('fieldRow') }>
						<label className={ sx('fieldLabel', { withValue: focusUsername || username }) }>
							<span>Username</span>
							<input
								type='text'
								name='username'
								ref={ (input) => { this.usernameInput = input; }}
								value={ username }
                                onFocus={ this.onUsernameFocus.bind(this) }
                                onBlur={ this.onUsernameBlur.bind(this) }
								onChange={ this.onUsernameChange.bind(this) }
								className={ sx('textInput') }
							/>
						</label>
					</div>
					<div className={ sx('fieldRow') }>
						<label className={ sx('fieldLabel', { withValue: focusPassphrase || passphrase }) }>
							<span>Passphrase</span>
							<input
								type='password'
								name='passphrase'
								ref={ (input) => { this.passphraseInput = input; }}
								value={ passphrase }
                                onFocus={ this.onPassphraseFocus.bind(this) }
                                onBlur={ this.onPassphraseBlur.bind(this) }
								onChange={ this.onPassphraseChange.bind(this) }
								className={ sx('textInput') }
							/>
						</label>
					</div>
					<div className={ sx('fieldRow') }>
						<button
							className={ sx('loginBtn') }
							disabled={ !username || !passphrase }
							onClick={ this.onLogin.bind(this) }
						>
							Login
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default connect(
	(state, props) => ({
		responsive: app.selectors.getResponsive(state, props),
		isLoggedIn: auth.selectors.isLoggedIn(state),
	}),
	{
		login: auth.actions.loginRequest,
		//login: authOps.login,
		//openModal: modalOps.open,
	},
)(LoginPage);
