import { createSelector } from '@reduxjs/toolkit';

const identity = state => state.auth;

const getLoggedInUserId = createSelector(
    [
        identity,
    ],
    auth => auth.loggedInUserId
);

const getAccessToken = createSelector(
    [
        identity,
    ],
    auth => auth.accessToken
);

const isLoggedIn = createSelector(
    [
        getAccessToken,
    ],
    accessToken => !!accessToken
);

const isRefreshing = createSelector(
    [
        identity,
    ],
    auth => auth.refreshPending,
);

const loginDetermined = createSelector(
    [
        identity,
    ],
    auth => auth.loginDetermined
);

export default {
	getLoggedInUserId,
	getAccessToken,
	isLoggedIn,
	isRefreshing,
	loginDetermined,
};
