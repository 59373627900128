import { createSelector } from '@reduxjs/toolkit';
import * as r from 'ramda';

import { selectors as stories } from '@state/stories';

export const getIds = (state, props) => state.feed.items;
export const getPage = (state, props) => state.feed.page;
export const getLast = (state, props) => state.feed.items[ state.feed.items.length - 1 ];

export const getStories = createSelector(
    [
        getIds,
        stories.getStoriesMap,
    ],
    (ids, stories) => ids.map(id => stories[ id ])
);

export const makeGetStories = () => createSelectors(getStories, r.identity);

export default {
    getIds,
    getPage,
    getLast,
    getStories,
    makeGetStories,
};
