export default {
    byId: {
        createTagSuccess: (state, action) => {
            const tag = action.payload.tag;
            state[ tag._id ] = tag;
        },
        getTagsSuccess: (state, action) => {
            const tags = action.payload.tags;
            tags.forEach(tag => {
                state[ tag._id ] = tag;
            });
        },
        deleteTagSuccess: (state, action) => {
            const tag = action.payload.tag;
            delete state[ tag._id ];
        },
    },
    allIds: {
        createTagSuccess: (state, action) => {
            const tag = action.payload.tag;
            state.push(tag._id);
        },
        getTagsSuccess: (state, action) => {
            const tags = action.payload.tags;

            tags.forEach(tag => {
                if (!state.includes(tag._id)) {
                    state.push(tag._id);
                }
            });
        },
        deleteTagSuccess: (state, action) => {
            const tag = action.payload.tag;
            state.splice(state.findIndex(id => tag._id === id), 1);
        },
    },
};
